import { MyWindow } from "./globals";
declare var window: MyWindow;

export class MainVars {
	// Main variables
	startGridHeight: number = $(window).height();
	gridLoaded: boolean = false;
	// Check to see if we're on a device that has touch support
	isTouchSupported: boolean = "ontouchstart" in document.documentElement;
	defFromTop: number = 150;
	detailsShown: boolean = false;
	curPopupId: string = null;
	// minSm: 576, minMd: 768, minLg: 992, minXl: 1200
	breakpoints: Breakpoints = {
		minSm: 576,
		minMd: 768,
		minLg: 992,
		minXl: 1200,
		maxXs: 576 - 0.02,
		maxSm: 768 - 0.02,
		maxMd: 992 - 0.02,
		maxLg: 1200 - 0.02
	};

	// Source variables
	dataAdapter: any = null;
	brandAdapter: any = null;
	subBrandAdapter: any = null;
	commodityAdapter: any = null;
	
	pagerSizes: Array<string> = ['10','25','50','100', '250', '500'];
	handleFilter:boolean = false;

	brandNames: Array<string> = ["all"];
	subBrands: Array<string> = ["all"];
	commodities: Array<string> = ["all"];

	isBulk:boolean = false;
	chipSet: any = null;
	mainGrid: jqwidgets.jqxGrid = null;
	gridPager: any = null;

	// Expanders
	brandHolder: jqwidgets.jqxExpander = null;
	subBrandHolder: jqwidgets.jqxExpander = null;
	herbHolder: jqwidgets.jqxExpander = null;
	detailHolder: jqwidgets.jqxExpander = null;
	infoHolder: jqwidgets.jqxExpander = null;

	// List boxes
	brandBox: jqwidgets.jqxListBox = null;
	subBrandBox: jqwidgets.jqxListBox = null;
	herbBox: jqwidgets.jqxListBox = null;

	source: any = {
		datatype: "json",
		contenttype: "application/json",
		type: "POST",
		datafields: [
			{ name: "BrandTitle" },
			{ name: "BrandName" },
			{ name: "BrandCode" },
			{ name: "Commodity" },
			{ name: "ItemNumber" },
			{ name: "Description" },
			{ name: "DescriptionFull" },
			{ name: "Size" },
			{ name: "UpcCode" },
			{ name: "Kosher" },
			{ name: "Designation" },
			{ name: "MarketingComments" },
			{ name: "WebCatalog" },
			{ name: "picThumbnail" },
			{ name: "picMain" },
			{ name: "CommodityNotes" },
			{ name: "Available" },
			{ name: "StockItem" },
			{ name: "Products", type: "array" },
			{ name: "bComCode", type: "string" }
		],
		hierarchy:
		{
			root: 'Products'
		},
		id: "ItemNumber",
		url: "api/product/readall.php",
		cache: false,
		root: "records",
		data: {
			searchterm: "",
			brands: ["all"],
			subbrands: ["all"],
			commodities: ["all"],
			designations: ["all"],
			kosher: "",
			bulk: "n",
			stock: ""
		},
		beforeprocessing: (data) => {
			//console.log(data);
			this.source.totalrecords = data.TotalRows;
		},
		formatdata: function(data)
		{
			//console.log(data);
			return JSON.stringify(data);
		}
	};
	subcatSource: any = {
		datatype: "json",
		contenttype: "application/json",
		type: "POST",
		url: "/api/category/readsubcats.php",
		datafields: [
			{ name: "BrandTitle" },
			{ name: "BrandName" },
			{ name: "Commodity" }
		],
		data: {
			searchterm: "",
			brands: ["all"],
			designations: ["all"],
			bulk: "",
			kosher: ""
		},
		formatdata: function(data)
		{
			return JSON.stringify(data);
		}
	};
	commSource: any = {
		datatype: "json",
		contenttype: "application/json",
		type: "POST",
		url: "/api/commodity/readherbs.php",
		datafields: [
			{ name: "Commodity" }
		],
		data: {
			searchterm: "",
			brands: ["all"],
			subbrands: ["all"],
			designations: ["all"],
			industrial: "n",
			kosher: "",
			bulk: "n",
			stock: ""
		},
		formatdata: function(data)
		{
			return JSON.stringify(data);
		}
	};
	brandSource:any = {
		datatype: "json",
		contenttype: "application/json",
		type: "POST",
		//url: "productcats.php",
		//url: "/api/category/read.php",
		url: "/api/category/readbrands.php",
		datafields: [
			{ name: "BrandTitle" }
		],
		data: {
			searchterm: "",
			brands: ["all"],
			designations: ["all"],
			bulk: "",
			kosher: ""
		},
		formatdata: function(data)
		{
			return JSON.stringify(data);
		}
	};
}

interface Breakpoints {
	// minSm: 576, minMd: 768, minLg: 992, minXl: 1200
	minSm?: number;
	minMd?: number;
	minLg?: number;
	minXl?: number;
	// maxXs: 576-0.02, maxSm: 768-0.02, maxMd: 992-0.02, maxLg: 1200-0.02
	maxXs?: number;
	maxSm?: number;
	maxMd?: number;
	maxLg?: number;
}