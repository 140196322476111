import { MainVars } from "./vars";
import { MyWindow } from "./globals";

declare var window: MyWindow;

export class EventHandlers {

	constructor(mVars: MainVars){}

	/**
	 * Callback handler for the jqWidgets grid page changed event
	 * @param  {any} event	The event from the jqWidgets grid
	 * @returns void
	 */
	gridPageChanged(event: any): void {
		let $var = window.ocConVars, $func = window.ocControl;
		// Return the top of the page. Previously this was performed by using
		// window.location.href and setting it to the anchor tag, which would
		// bring the tag to the top if an anchor name was not also supplied.
		// This has the downside of adding the # to the address bar, and as such,
		// is now being performed using the scrollTop functions on the document
		// body. The window line has been left in and commented for future
		// reference.
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
		
		//$("#set_pager_size").selectpicker("val")
		let sPage = $("#set_pager_size").val();
		
		if(sPage !== $var.mainGrid.pagesize) {
			$("#set_pager_size").val($var.mainGrid.pagesize);
			$("#set_pager_size").selectpicker("refresh");
		}
	}

	/**
	 * Callback handler for the jqWidgets.jqxGrid onRendered event
	 * @returns void
	 */
	gridRendered(): void {
		// Intialize the control variable to the main global variables.
		let $var = window.ocConVars, $func = window.ocControl;
		// Call the new extension written for the jqWidgets grid, which can be found in /scripts/jqxgrid.refreshrows.extension.js,
		// responsible for resizing without redrawing cell contents.
		$var.mainGrid.exRenderExpansion();
		//$func.getWindowDimensions();
		$func.setProductTitleWidth();
	}

	/**
	 * Callback event handler for the jqWidgets.jqxGrid ready event.
	 * @returns void
	 */
	gridReady(): void {
		// Intialize the control variable to the main global variables.
		let $var = window.ocConVars, $func = window.ocControl, $grid = $var.mainGrid;
		// Call the new extension written for the jqWidgets grid, which can be found in /scripts/jqxgrid.refreshrows.extension.js,
		// responsible for resizing without redrawing cell contents.
		// $var.mainGrid.exRenderExpansion();
		// // Get the complete number of rows returned from the database
		// let rCount = $var.mainGrid.getdatainformation().rowscount;
		
		// // Add the "All Products" option to the Number of Products Per Page in the filters menu
		// let nItem = `<option value="${rCount}">All Products</option>`;
		// $("#set_pager_size").append(nItem);
		// $("#set_pager_size").selectpicker("refresh");

		// $var.pagerSizes.push(rCount);
		// $var.mainGrid.pagesizeoptions = $var.pagerSizes;
		// (<any>$("#gridpagerlistjqxgrid")).jqxDropDownList("addItem", {label: "All", value: rCount});

		$func.setProductTitleWidth();
	}

	/**
	 * Callback event handler for the show all details and show
	 * basic info button click event.
	 * @param  {JQuery.ClickEvent} event	The jQuery.ClickEvent passed in via the callback handler
	 * @returns void
	 */
	btnMoreDetailsClick(event: JQuery.ClickEvent): void {
		// Initalize a reference to the control variables.
		let $var = window.ocConVars, $func = window.ocControl, $event = window.ocEvents;
		// Initialize the local variables
		let $btn: any = $(this),				// The jQuery reference to the button
			rowindex = $btn.data("rowindex");	// The visible row index for the product the button applies to

		if($btn.data("status") === "hidden") {
			// If the data-status property of the button is set to "hidden", call the ControlFunctions.showAllDetails method,
			// passing the jQuery reference to the button.
			$func.showAllDetails($btn);
		} else if($btn.data("status") === "shown") {
			// If the data-status property of the button is set to "shown", call the ControlFunctions.hideAllDetails method,
			// passing the jQuery reference to the button.
			$func.hideAllDetails($btn);
		}
	}

	/**
	 * Callback event handler for the filter by All Items/Bulk Items radio buttons
	 * @param  {any} event	The event passed in via the callback handler
	 * @returns void
	 */
	bulkItemChange(event: any): void {
		// Initialize the control variable; only the functions is needed here.
		let $fun = window.ocControl;
		// Call the ControlFunctions.showBulk method with the id property of the radio button selected.
		$fun.showBulk($(this).prop("id"));
	}

	/**
	 * Callback event handler for the click event of the expand all groups button
	 * @param  {any} event	The event passed in via the callback handler
	 * @returns void
	 */
	btnExpandClick(event: any): void {
		// Initialize the control variables
		let $vars = window.ocConVars, $grid = $vars.mainGrid;
		// Call the expand all groups method on the jqWidgets grid
		$grid.expandallgroups();
	}

	/**
	 * Callback event handler for the click event of the collapse all groups button
	 * @param  {any} event	The event passed in via the callback handler
	 * @returns void
	 */
	btnCollapseClick(event: any): void {
		// Initialize the control variables
		let $vars = window.ocConVars, $grid = $vars.mainGrid;
		// Call the collapse all groups method on the jqWidgets grid
		$grid.collapseallgroups();
	}

	/**
	 * Callback event handler for the click event of the search (magnifying glass) button
	 * in the filters menu
	 * @param  {any} e	The event passed in via the callback handler
	 * @returns void
	 */
	searchClick(e: any): void {
		// Prevent the default event
		e.preventDefault();
		// Call the ControlFunctions.doSearch() method with the value
		// of the search input box.
		window.ocControl.doSearch($("#searchProduct").val());
		// Refocus on the search input box.
		$("#searchProduct").focus();
	}

	/**
	 * Callback event handler for the click event of the clear search (x icon) button
	 * in the filters menu
	 * @param  {any} e	The event passed in via the callback handler
	 * @returns void
	 */
	searchClear(e: any): void {
		// Prevent the default action
		e.preventDefault();
		// Ensure that the search input box is empty
		$("#searchProduct").val(null);
		// Call the ControlFunctions.doSearch() method with a null value, clearing the search.
		window.ocControl.doSearch(null);
		// Refocus on the search input box
		$("#searchProduct").focus();
	}

	/**
	 * Callback event handler for the keypress event within the search input box in the
	 * filters menu
	 * @param  {any} e	The event passed in via the callback
	 * @returns void
	 */
	searchKeypress(e: any): void {
		// Check if the current keypress is any of the modifier codes
		if(e.which == 17
			|| e.keyCode == 17
			|| e.which == 18
			|| e.keyCode == 18
			|| e.which == 16
			|| e.keyCode == 16
			|| e.which == 9
			|| e.keyCode == 9
			|| e.which == 20
			|| e.keyCode == 20
			|| e.which == 37
			|| e.keyCode == 37
			|| e.which == 40
			|| e.keyCode == 40
			|| e.which == 39
			|| e.keyCode == 39
			|| e.which == 38
			|| e.keyCode == 38
			|| e.which == 45
			|| e.keyCode == 45
			|| e.which == 36
			|| e.keyCode == 36
			|| e.which == 33
			|| e.keyCode == 33
			|| e.which == 35
			|| e.keyCode == 35
			|| e.which == 34
			|| e.keyCode == 34) {
			// Prevent any action when they're pressed
			e.preventDefault();
		} else if(e.which == 13 || e.keyCode == 13) {
			// Check if the button is the enter button, and prevent it's default action
			e.preventDefault();
			// Call the EventHandlers.searchClick handler, passing in the current event
			window.ocEvents.searchClick(e);
		} else if($(this).val() === "") {
			// Otherwise if the search box is empty, immediately clear the search
			e.preventDefault();					// Prevent the default action
			$("#searchProduct").val(null);		// Set the search input to null
			window.ocControl.doSearch(null);	// Call ControlFunctions.doSearch with a null value
			$("#searchProduct").focus();		// Refocus on the search input box
		}
	}

	/**
	 * Callback event handler for the page size change event
	 * @param  {any} event
	 * @param  {number} clickedIndex
	 * @param  {boolean} isSelected
	 * @param  {any} previousValue
	 * @returns void
	 */
	selectPagerChange(event: any, clickedIndex: number, isSelected: boolean, previousValue: any): void {
		// Initialize the control variables
		let $vars = window.ocConVars, $events = window.ocEvents, $fun = window.ocControl, $grid = $vars.mainGrid;
		// Initialize the local value variable, by retrieving the string value from the select box, and parsing it
		// to an integer.
		let nValue: number = parseInt((<string>$(this).val()));

		// Check to make sure the grid has finished initialization
		if(typeof($grid) !== "undefined")
		{
			//console.log(`Changing page size from ${$grid.pagesize} to ${nValue}`);
			// Set the grid size to the size selected in the dropdown
			$grid.pagesize = nValue;
			//console.log(`New grid size is: ${$grid.pagesize}`);
			$grid.updatebounddata("data");
			// Rerender the grid
			$grid.render();
			// Ensure the first page is selected
			$grid.gotopage(0);
		}
	}

	/**
	 * Callback event handler for the change event in the Filter by Brands list box
	 * @param  {any} event	The jqWidgets event
	 * @returns void
	 */
	listBrandChange(event: any): void {
		// Initialize the control variables
		let $vars = window.ocConVars, $events = window.ocEvents, $fun = window.ocControl, $box = $vars.brandBox;
		// Initialize the local variables
		let checkedItems = $box.getCheckedItems(), items = $box.getItems(), brandNames = [];
		// Loop through the items in the checkbox
		items.filter((val, index) => {
			let tArr = [];
			if(val.checked) {
				//console.log("==== Filter Test ====");
				//console.log("Index: ", index, " Val: ", val);
				if(!$vars.chipSet.chipExists(val.label, false)) {
					//console.log(`Chip ${val.label} is not in $vars.chipSet`);
					// if a chip does not exist for the item, add one for it.
					$vars.chipSet.addChip({title: val.label, chipType: "brands", brandName: val.label.replace(" ", "")});
				}
				//console.log("=====================");
				
				//tArr.push(val);
			} else if(!val.checked && !val.isGroup) {
				//console.log(`${val.label} is not checked`);
				if($vars.chipSet.chipExists(val.label, false)) {
					//console.log("==== Filter Test - Not Checked but Chip Exists ====");
					//console.log(`Chip ${val.label} is in $vars.chipSet`);
					//console.log("Data: ", $vars.chipSet.chipGetData(val.label, false));
					//console.log("=====================");
					// If a chip exists for it, remove the chip.
					$vars.chipSet.removeChip(val.label);
				}
			}
		});
		/*
		for(let i = 0; i < items.length; i++)
		{
			// Initialize local variables to the for loop, for a label and value
			let label = items[i].label, value = items[i].label.replace(" ", "");
			if(items[i].checked) {
				// If the current item is checked
				if(!$vars.chipSet.chipExists(label, false)) {
					// If a chip does not exist for the current item, create one
					$vars.chipSet.addChip({title: label, chipType: "brands", brandName: value});
				}
			} else if(!items[i].checked && !items[i].isGroup) {
				// Otherwise, if it's not checked and not a group
				if($vars.chipSet.chipExists(label, false)) {
					// If a chip does exist for it, remove it
					$vars.chipSet.removeChip(label);
				}
			}
		}*/

		if(checkedItems.length === 0) {
			// If there are no checked items, set the global brand names to all
			$vars.brandNames = ["all"];
			// Hide the brand-category box
			$("#subbrand_wrapper").hide();
		} else {
			// Empty out the global brand names variable by reinitializing it as an empty array.
			$vars.brandNames = [];
			// Loop through the checked items
			for(var i = 0; i < checkedItems.length; i++) {
				// Add the current checked item to the global brands array
				$vars.brandNames.push(checkedItems[i].label);
			}
			if(!$vars.isBulk) {
				// Show the brand-category box
				$("#subbrand_wrapper").show();
			}
			
		}

		if($vars.source.data.subbrands[0] !== "all") {
			// If the brand-category is not already set to all, set it to all.
			$vars.source.data.subbrands=["all"];
		}
		// Initialize a reference to the main grid
		let $grid = $vars.mainGrid;
		// Set the main grid source brands to the global brand names array
		$vars.source.data.brands = $vars.brandNames;

		// Update the main grid's data
		$grid.updatebounddata("data");
		// Rerender the main grid
		$grid.render();
		// Ensure the first page of the grid is displayed
		$grid.gotopage(0);

		// Update the brand-category list box
		$fun.updateSubBrand();
		// Update the herb/spice list box
		$fun.updateCommodity();
	}

	/**
	 * Callback event handler for the change event in the Filter by Brand-Category list box
	 * @param  {any} event	The jqWidgets event
	 * @returns void
	 */
	listSubBrandChange(event: any): void {
		// Initialize the control variables
		let $vars = window.ocConVars, $events = window.ocEvents, $fun = window.ocControl, $box = $vars.subBrandBox;
		// Initialize the local variables
		let checkedItems = $box.getCheckedItems(), items = $box.getItems(), brandNames = [];

		// Loop through all the items in the list box
		items.filter((val, index) => {
			let tArr = [];
			if(val.checked) {
				//console.log("==== Filter Test ====");
				//console.log("Index: ", index, " Val: ", val);
				if(!$vars.chipSet.chipExists(val.label, false)) {
					//console.log(`Chip ${val.label} is not in $vars.chipSet; adding`);
					// if a chip does not exist for the item, add one for it.
					$vars.chipSet.addChip({title: val.label, chipType: "subbrands", brandName: val.label});
				}
				//console.log("=====================");
				
				//tArr.push(val);
			} else if(!val.checked && !val.isGroup) {
				//console.log(`*** ${val.label} is not checked`);
				if($vars.chipSet.chipExists(val.label, false)) {
					//console.log("==== Filter Test - Not Checked but Chip Exists ====");
					//console.log(`Chip ${val.label} is in $vars.chipSet`);
					//console.log("Data: ", $vars.chipSet.chipGetData(val.label, false));
					//console.log("=====================");
					// If a chip exists for it, remove the chip.
					$vars.chipSet.removeChip(val.label);
				}
			}
			else {
				//console.log("Invalid step!: ", val);
			}
		});
		/*
		for(var i = 0; i < items.length; i++)
		{
			// Get the current label
			let slabel = items[i].label;
			if(items[i].checked) {
				// If the item is checked
				if(!$vars.chipSet.chipExists(slabel, false)) {
					// If a chip doesn't exist for it, create one.
					$vars.chipSet.addChip({title: slabel, chipType: "subbrands", brandName: slabel});
				}
			} else if(!items[i].checked && !items[i].isGroup) {
				// Otherwise, if the item is not checked and not a group
				if($vars.chipSet.chipExists(slabel, false)) {
					// If a chip does exist for it, remove it.
					$vars.chipSet.removeChip(slabel);
				}
			}
		}*/

		if(checkedItems.length === 0) {
			// If there are no checked items, set the global sub-brands variable to all
			$vars.subBrands = ["all"];
		} else {
			// Empty out the global sub-brands variable by reinitializing it as an empty array
			$vars.subBrands = [];
			// Loop through the checked items
			for(var i =0; i < checkedItems.length; i++) {
				// Add the current checked item to the sub-brands array
				$vars.subBrands.push(checkedItems[i].label);
			}
		}

		// Initialize a reference to the main grid
		let $grid = $vars.mainGrid;
		// Set the main grid's sub-brand source to be the global sub-brand array
		$vars.source.data.subbrands = $vars.subBrands;

		// Update the main grid's data
		$grid.updatebounddata("data");
		// Rerender the main grid
		$grid.render();
		// Ensure the first page is displayed
		$grid.gotopage(0);
		// Update the herb/spice list box
		$fun.updateCommodity();
	}

	/**
	 * Callback event handler for the change event in the Filter by Herb/Spice list box
	 * @param  {any} event	The jqWidgets event
	 * @returns void
	 */
	listHerbChange(event: any): void {
		// Initialize the control variables
		let $vars = window.ocConVars, $events = window.ocEvents, $fun = window.ocControl, $box = $vars.herbBox;
		// Initialize the local variables
		let checkedItems = $box.getCheckedItems(), items = $box.getItems(), brandNames = [];

		items.filter((val, index) => {
			let tArr = [];
			if(val.checked) {
				//console.log("==== Filter Test ====");
				//console.log("Index: ", index, " Val: ", val);
				if(!$vars.chipSet.chipExists(val.label, false)) {
					//console.log(`Chip ${val.label} is not in $vars.chipSet`);
					// if a chip does not exist for the item, add one for it.
					$vars.chipSet.addChip({title: val.label, chipType: "commodity", brandName: val.label});
				}
				//console.log("=====================");
				
				//tArr.push(val);
			} else if(!val.checked && !val.isGroup) {
				//console.log(`${val.label} is not checked`);
				if($vars.chipSet.chipExists(val.label, false)) {
					//console.log("==== Filter Test - Not Checked but Chip Exists ====");
					//console.log(`Chip ${val.label} is in $vars.chipSet`);
					//console.log("Data: ", $vars.chipSet.chipGetData(val.label, false));
					//console.log("=====================");
					// If a chip exists for it, remove the chip.
					$vars.chipSet.removeChip(val.label);
				}
			}
		});

		if(checkedItems.length === 0) {
			// If there are no checked items, set the global checked items varible to all items
			$vars.commodities = ["all"];
		} else {
			// If there are checked items, empty out the global checked items variable by reinitializing it as an empty array
			$vars.commodities = [];
			// Loop through all the checked items
			for(var i = 0; i < checkedItems.length; i++) {
				// Add the current checked items to the global checked items array
				$vars.commodities.push(checkedItems[i].label);
			}
		}
		
		// Set the global main grid source herb array to the global herb array
		$vars.source.data.commodities = $vars.commodities;

		// Initialize a reference to the main grid
		let $grid = $vars.mainGrid;
		// Set the main grid source brand-category to the global brand-category array
		$vars.source.data.subbrands = $vars.subBrands;

		// Update the grid's data
		$grid.updatebounddata("data");
		// Rerender the main grid
		$grid.render();
		// Ensure the first page is shown on the main grid.
		$grid.gotopage(0);
		$grid.cleargroups();
		if($vars.isBulk) {
			$grid.addgroup("Commodity");
		} else if(!$vars.isBulk) {
			$grid.addgroup("BrandTitle");
			// Add the brand name group to the grid
			$grid.addgroup("BrandName");
		}
	}

	/**
	 * Callback event handler for the change evnet on the filter by organic designation switches
	 * @param  {any} event	The jQuery change event
	 * @returns void
	 */
	checkInfoDesignationChange(event: any): void {
		// Initialize the control variables and a reference to the main grid
		let $vars = window.ocConVars, $events = window.ocEvents, $fun = window.ocControl, $grid = $vars.mainGrid;
		// Initialize the local variables
		let cItems = $("input[data-action=desFilter]:checked"), designations = [];

		if(cItems.length === 0) {
			// If none of the switches are selected, set the designations to all
			designations = ["all"];
		} else {
			// If one or more of the designations are selected, blank out the designations
			// by reinitializing it as an empty array.
			designations = [];
			// Loop through the selected designations
			for(var i = 0; i < cItems.length; i++) {
				// Get a reference to the current switch
				let t = cItems[i];
				// Add the designation switch's value to the local designation array
				designations.push((<any>t).value);
			}
		}

		if($.inArray("ORG", designations) > -1) {
			// Using jQuery, check to see if "ORG" is in the array for organic
			if(!$vars.chipSet.chipExists("ORG", true)) {
				// If it does, and a chip doesn't exist, add a chip to the chip area
				$vars.chipSet.addChip({title: "Organic", chipType: "ORG"});
			}
		} else {
			// If it doesn't exist in the array
			if($vars.chipSet.chipExists("ORG", true)) {
				// If a chip exists for organic, remove it
				$vars.chipSet.removeChip("ORG",true, false);
			}
		}

		if($.inArray("WC", designations) > -1){
			// Check to see if "WC" is in the array for Wildcrafted
			if(!$vars.chipSet.chipExists("WC", true)) {
				// If it is, and a chip doesn't exist, add a chip to the chip area
				$vars.chipSet.addChip({title: "Wildcrafted", chipType: "WC"});
			}
		} else {
			// If it doesn't exist in the array
			if($vars.chipSet.chipExists("WC", true)) {
				// If a chip exists, remove it
				$vars.chipSet.removeChip("WC",true, false);
			}
		}

		if($.inArray("PQ", designations) > -1) {
			// Check to see if "PQ" is in the array for "Pure Quality"
			if(!$vars.chipSet.chipExists("PQ", true)) {
				// If it is, and a chip doesn't exist for it, add a chip to the chip area
				$vars.chipSet.addChip({title: "Pure Quality", chipType: "PQ"});
			}
		} else {
			// If it isn't in the area
			if($vars.chipSet.chipExists("PQ", true)) {
				// If a chip exists, remove it
				$vars.chipSet.removeChip("PQ",true, false);
			}
		}

		// Set the global source organic designations variable to the local designations array
		$vars.source.data.designations = designations;
		// Update the main grid's data
		$grid.updatebounddata("data");
		// Rerender the main grid
		$grid.render();
		// Ensure the grid is on page one
		$grid.gotopage(0);
	}

	/**
	 * Callback event handler for the Filter by Stock Item switch change event
	 * @param  {any} event	The jQuery event
	 * @returns void
	 */
	checkInfoStockChange(event: any): void {
		// Initialize the control variables and a reference to the main gridd
		let $vars = window.ocConVars, $events = window.ocEvents, $fun = window.ocControl, $grid = $vars.mainGrid;

		// Check to see if the switch is checked
		if($(this).is(":checked")) {
			// If it's checked, add a chip to the chip area
			$vars.chipSet.addChip({title: "Stock Items", chipType: "stock"});
			// Set the global source stock data to yes
			$vars.source.data.stock="y";
		} else {
			// If it's not checked, remove the chip
			$vars.chipSet.removeChip("stock",true);
			// Set the global source stock data to empty
			$vars.source.data.stock = "";
		}

		// If the global brand-category source has been changed, reset it to be
		// all
		if($vars.source.data.subbrands[0] !== "all") {
			$vars.source.data.subbrands=["all"];
		}
		
		// Update the main grid's data
		$grid.updatebounddata("data");
		// Rerender the main grid
		$grid.render();
		// Ensure the grid is on page one
		$grid.gotopage(0);
		// Update the brand-catgegory list box
		$fun.updateSubBrand();
		// Update the herb/spice list box
		$fun.updateCommodity();
	}

	/**
	 * Callback event handler for the Filter by Kosher switch change event
	 * @param  {any} event	The jQuery event
	 * @returns void
	 */
	checkInfoKosherChange(event: any): void {
		// Initialize the control variables and a reference to the main grid
		let $vars = window.ocConVars, $events = window.ocEvents, $fun = window.ocControl, $grid = $vars.mainGrid;
		// Check if the kosher switch is checked
		if($(this).is(":checked")) {
			// If it is, add a chip to the chip area
			$vars.chipSet.addChip({title: "Kosher", chipType: "kosher"});
			// Set the global source kosher data to yes
			$vars.source.data.kosher = "y";
		} else {
			// If it's not, remove the kosher chip
			$vars.chipSet.removeChip("kosher",true);
			// Set the global source kosher data to empty
			$vars.source.data.kosher = "";
		}
		// If the global brand-category has changed, reset it to all
		if($vars.source.data.subbrands[0] !== "all") {
			$vars.source.data.subbrands=["all"];
		}
		// Update the main grid's data
		$grid.updatebounddata("data");
		// Rerender the main grid
		$grid.render();
		// Ensure the grid is on page one
		$grid.gotopage(0);
		// Update the brand-category list box
		$fun.updateSubBrand();
		// Update the herb/spice list box
		$fun.updateCommodity();
	}

	chipsAdding(e: any, chip: any) {
		//console.log("Adding chip");
		//console.log(e);
		//console.log(chip);
	}

	/**
	 * Event handler for when chips/filters are added to the chip area.
	 * @param  {any} e		The event passed from the event listener
	 * @param  {any} chip	The chip object itself.
	 * @returns void
	 */
	chipsAdded(e:any, chip:any) :void {
		let $var = window.ocConVars, $func = window.ocControl, $events = window.ocEvents;
		// Call the method to update the column title position and chip position.
		$func.setProductSubTitleStart();
	}

	/**
	 * Callback event handler for the chips removed event
	 * @param  {any} e		The event from the chip
	 * @param  {any} chip	The chip triggering the event
	 * @returns void
	 */
	chipsRemoved(e: any, chip: any): void {
		// Initalize the control variables and a reference to the main grid
		let $vars = window.ocConVars, $events = window.ocEvents, $fun = window.ocControl, $grid = $vars.mainGrid;
		// Declare local variables
		let $box, curItem;
		// Switch between different chip types
		switch(chip.type)
		{
			case "search":
				// Search chips
				if(chip.return) {
					// Perform the null search here; this is the default functionality.
					$("#searchProduct").val(null);
					$fun.doSearch(null);
				}
				break;
			case "kosher":
				// Kosher chips
				if($("#kosher").is(":checked")) {
					// If the kosher switch is checked
					// Uncheck the switch
					$("#kosher").prop("checked", false);
					// Ensure the chip is removed
					$vars.chipSet.removeChip("kosher",true);
					// Set the global source kosher data to empty
					$vars.source.data.kosher = "";
					// Update the grid data
					$grid.updatebounddata("data");
					// Rerender the grid
					$grid.render();
					// Ensure the grid is on page one
					$grid.gotopage(0);
					// Update the brand-category list box
					$fun.updateSubBrand();
					// Update the herb/spice list box
					$fun.updateCommodity();
				}
				break;
			case "bulk":
				// Bulk chips
				if(chip.return) {
					// Check the "All Items" radio button
					$("#ShowConsumer").prop("checked", true).change();
				}
				break;
			case "ORG":
				// Uncheck the Filter by Organic switch
				$("#orgFilter").prop("checked", false).change();
				break;
			case "WC":
				// Uncheck the Filter by Wildcrafted switch
				$("#wcFilter").prop("checked", false).change();
				break;
			case "PQ":
				// Uncheck the Filter by Pure Quality switch
				$("#pqFilter").prop("checked", false).change();
				break;
			case "stock":
				// Stock chip
				if($("#stock").is(":checked")) {
					// If the stock switch is checked
					// Uncheck the stock switch
					$("#stock").prop("checked", false);
					// Ensure the stock chip is removed
					$vars.chipSet.removeChip("stock",true);
					// Set the global stock source data to empty
					$vars.source.data.stock = "";
					// Update the grid data
					$grid.updatebounddata("data");
					// Rerender the grid
					$grid.render();
					// Ensure the grid is on page one
					$grid.gotopage(0);
					// Update the brand-category list box
					$fun.updateSubBrand();
					// Update the herb/spice list box
					$fun.updateCommodity();
				}
				break;
			case "brands":
				// Set the local box varaiable to reference te brand box and the local
				// curItem variable to get the brand referenced by the chip
				$box = $vars.brandBox, curItem = $box.getItemByValue(chip.chip);
				// Uncheck the item referenced by the chip
				$box.uncheckItem(curItem);
				break;
			case "subbrands":
				// Set the local box variable to reference the brand box and the local
				// curItem variable to get the brand referenced by the chip
				$box = $vars.subBrandBox, curItem = $box.getItemByValue(chip.chip);
				// Uncheck the item referenced by the chip
				$box.uncheckItem(curItem);
				break;
			case "commodity":
				// Set the local box variable to reference the brand box and the local
				// curItem variable to get the brand referenced by the chip
				$box = $vars.herbBox, curItem = $box.getItemByValue(chip.chip);
				// Uncheck the item referenced by the chip
				$box.uncheckItem(curItem);
				//console.log("Chip: ", chip);
				//console.log("curItem: ", curItem);
				//console.log("box: ", $box);
				break;
		}
		$fun.setProductSubTitleStart();
	}

	/**
	 * Callback event handler for the window scroll event
	 * @returns void
	 */
	scrollFunction(): void {
		// Initialize control variables
		let $var = window.ocConVars;
		// Get the reference to the "go to top" button
		let topBtn = document.getElementById("topButton");
		// Check the distance the document has been scrolled from the top. The distance can be found in the
		// /scripts/ts/vars.ts file in the defFromTop variable; default is currently 150px from top.
		if(document.body.scrollTop > $var.defFromTop || document.documentElement.scrollTop > $var.defFromTop) {
			// Add the class to show the go to top button
			topBtn.classList.add("orgFabShow");
		} else {
			// Remove the class, which hides the go to top button
			topBtn.classList.remove("orgFabShow");
		}
	}
	
	/**
	 * Callback event handler for the filter menu open click event
	 * @param  {any} e	The jQuery click event
	 * @returns void
	 */
	navOpen(e: any):void {
		// Initialize the control variables
		let $var = window.ocConVars, $func = window.ocControl, $event = window.ocEvents;
		// Get the reference to the filter menu and the jQuery reference to the button
		let nav = document.getElementById("filterCollapse"), $btn = $(e.target);
		// Check which button was clicked; if it was the filter override in the nav
		// menu, we can safely toggle the menu open and closed. If it was any other
		// manner of opening, we only add the fSideOpen class, as navClose is assigned
		// to a separate event handler.
		if($btn.hasClass("filterOverride") || $btn.hasClass("filterOverrideIcon")) {
			// Toggle the fSideOpen class. This will open it when closed, and close it
			// when open.
			nav.classList.toggle("fSideOpen");
		} else {
			// Add the fSideOpen class; this only opens the menu.
			nav.classList.add("fSideOpen");
		}

		// Remove scrolling on the body element while the nav is open
		if($(nav).hasClass("fSideOpen"))
		{
			$("body").addClass("bodyNoScroll");
		} else {
			$("body").removeClass("bodyNoScroll");
		}

		/** Fix for jqWidgets auto-sizing methods on DOM updates */
		// Measure the width of the window, minus 40 pixels. The extra pixels saved off,
		// accounts for any window chrome such as scroll bars or the like.
		let wWidth = $(window).width() - 40;
		// Create an auto-repeating interval with a referencable handle. This interval
		// repeats every 15 milliseconds until the conditions contained within are met.
		let tOut = setInterval(() => {
			// Check to ensure that the menu has the fSideOpen class, and the width is
			// greater than or equal to the width variable defined above.
			if($(nav).hasClass("fSideOpen") && $(nav).width() >= wWidth) {
				// If the conditions are met, clear the interval using the tOut handle.
				clearInterval(tOut);
				// Call the ControlFunctions.refreshLists method
				$func.refreshLists();
			}
		},15);
	}

	/**
	 * Callback event handler for the filter menu close click event
	 * @returns void
	 */
	navClose(): void {
		// Get the filter menu
		let nav = document.getElementById("filterCollapse");
		// Remove the open class from the filter menu.
		nav.classList.remove("fSideOpen");
		// Remove the bodyNoScroll class from the body element
		$("body").removeClass("bodyNoScroll");
	}

	/**
	 * Callback event handler for the go to top button click event
	 * @returns void
	 */
	topFunction(): void {
		// Scroll the body element to 0px
		document.body.scrollTop = 0;
		// Scroll the documentElement to 0px
		document.documentElement.scrollTop = 0;
	}

	/**
	 * Callback event handler for the show all details button in the filters menu
	 * @param  {JQuery.ClickEvent} e	// The jQuery click event
	 * @returns void
	 */
	showAllDetailsClick(e: JQuery.ClickEvent): void {
		let $var = window.ocConVars, $events = window.ocEvents, $func = window.ocControl;
		e.preventDefault();
		e.stopPropagation();

		// // Get jQuery references to the needed DOM elements
		// let $btn = $("#showDetails"),
		// 	$titleBar = $("#testtitle"),
		// 	$isSub = $titleBar.find("#gridTitleSubRow2");
		
		// if(!$var.detailsShown && $isSub.length === 0) {
		// 	// The subheader doesn't currently exist on the page. Call updateProductSubTitle with the add parameter
		// 	$var.detailsShown = true;
		// 	window.detailsShown = true;
		// 	$btn.html(`Show Basic Info <i class="fas fa-caret-up"></i>`);
		// 	$func.updateProductSubTitle(0,false);
		// 	$func.updateProductDetails(false);
		// } else if($var.detailsShown && $isSub.length > 0) {
		// 	// The subheader does exist on the page. Call the updateProductSubTitle with the remove parameter
		// 	$var.detailsShown = false;
		// 	window.detailsShown = false;
		// 	$btn.html(`Show All Details <i class="fas fa-caret-down"></i>`);
		// 	$func.updateProductSubTitle(0,true);
		// 	$func.updateProductDetails(true);
		// } else {
		// 	// Somehow an unsynced state. Resync to not shown.
		// 	$var.detailsShown = false;
		// 	window.detailsShown = false;
		// 	$btn.html(`Show All Details <i class="fas fa-caret-down"></i>`);
		// 	$func.updateProductSubTitle(0,true);
		// 	$func.updateProductDetails(true);
		// }

		$func.runUpdateDetails();
	}
	
	/**
	 * Callback event handler for the close image click event
	 * @param  {any} e
	 */
	closeImage(e: any){
		// Initialize the local variables
		let oc_img = $("#oc_imgpop_dialog"), oc_overlay = $("#oc_imgpop_overlay"), tImg = oc_img.find("img");
		// Check to ensure the wrapper and the overlay actually exist. This fixes possible
		// nasty looking bugs if they for some reason have been removed from the DOM.
		if(oc_img.length > 0 && oc_overlay.length > 0)
		{
			// Check to see if the escape key was pressed, or the close image button was clicked
			if(e.keyCode == 27 || e.which == 27 || e.key === "Escape" || e.code === "Escape" || e.target.id === "oc_closeimg") {
				// Slowly fade out the popup wrapperr
				oc_img.fadeOut("slow", () => {
					// Quickly fade out the image overlay
					oc_overlay.fadeOut("fast");
					// Remove the overflow:hidden; css class
					$(`body`).removeClass("bodyNoScroll");
					// Remove the image from the popup wrapper
					$(tImg).remove();
					// Find the close icon "X" button, and remove the click event handler from it to avoid piling event handlers on it,
					// causing potential memory leaks.
					oc_img.find("i").off("click");
				});
			}
		}
	}

	/**
	 * Callback event handler for the PointerEvent PointerDown event. This handles multiple event types,
	 * and needs to capture and break out the various types into different functions based on the device
	 * and input type.
	 * @param  {any} e	The PointerEvent captured
	 * @returns void
	 */
	productImagePointerDown(e:any): void {
		let $var = window.ocConVars, $events = window.ocEvents, $func = window.ocControl;
		let img = e.target, $img = $(e.target), pType = e.pointerType;
		let lBreak = $var.breakpoints, wWidth = $(window).width();
		/*console.log("Current window width: ", wWidth);
		console.log("Breakpoints: \r\n\tminSm:", $var.breakpoints.minSm,
			"\r\n\tminMd:", $var.breakpoints.minMd,
			"\r\n\tminLg:", $var.breakpoints.minLg,
			"\r\n\tminXl:", $var.breakpoints.minXl,
			"\r\n\tmaxXs:", $var.breakpoints.maxXs,
			"\r\n\tmaxSm:", $var.breakpoints.maxSm,
			"\r\n\tmaxMd:", $var.breakpoints.maxMd,
			"\r\n\tmaxLg:", $var.breakpoints.maxLg);*/
		switch(pType) {
			case "mouse":
				// Mouse input
				if(wWidth < lBreak.maxMd) {
					// If it's a smaller screen size, such as a handheld device using an
					// external input, or a small-width browser window, don't show the pop-up
					// window. Instead, show the full-screen modal popup type window:
					$func.openImage($img.data("picture"),500);
				} else if(wWidth > lBreak.minLg) {
					// It's a large screen, such as a desktop or laptop that has a touch screen.
					// Use the popup image, which will require clicking/touching elsewhere on the
					// screen to dismiss the popup. This is accomplished with a click handler set
					// in the ControlFunctions.hideProductImageMouseover() that's called immediately
					// after ControlFunctions.showProductImageMouseover(). With a mouse, one can
					// also move the mouse out from overtop the image which will dismiss it. I have
					// yet to be able to successfully emulate this functionality on a mobile device,
					// nor have I found any examples of anyone whom has, including from companies such
					// as Google.
					$func.showProductImageMouseover(img);
					$func.hideProductImageMouseover();
				}
				break;
			case "pen":
				// Pen/stylus input
				if(wWidth < lBreak.minMd) {
					// It's a smaller screen, such as a mobile phone. Use the full screen popup
					$func.openImage($img.data("picture"),500);
				} else if(wWidth > lBreak.minMd && wWidth < lBreak.minLg) {
					// It's a medium screen, such as a tablet. Not sure what to do here, yet.
					// Perhaps break the image open method out into the ControlFunctions file, and
					// add the ability to pass in dimensions to it. For now, just call the openImage
					$func.openImage($img.data("picture"),575);
				} else if(wWidth > lBreak.minLg) {
					// It's a large screen, such as a desktop or laptop that has a touch screen.
					// Use the popup image, which will require clicking/touching elsewhere on the
					// screen to dismiss the popup. This is accomplished with a click handler set
					// in the ControlFunctions.hideProductImageMouseover() that's called immediately
					// after ControlFunctions.showProductImageMouseover()
					$func.showProductImageMouseover(img);
					$func.hideProductImageMouseover();
				}
				break;
			case "touch":
				// Touch screen input
				if(wWidth < lBreak.minMd) {
					// It's a smaller screen, such as a mobile phone. Use the full screen popup
					//max-width: 500px;
					//max-height: 1200px;
					$func.openImage($img.data("picture"),500);
				} else if(wWidth > lBreak.minMd && wWidth < lBreak.minLg) {
					// It's a medium screen, such as a tablet. Use the full screen popup with a
					// maximum image width of 575px.
					$func.openImage($img.data("picture"),575);
				} else if(wWidth > lBreak.minLg) {
					// It's a large screen, such as a desktop or laptop that has a touch screen.
					// Use the popup image, which will require clicking/touching elsewhere on the
					// screen to dismiss the popup. This is accomplished with a click handler set
					// in the ControlFunctions.hideProductImageMouseover() that's called immediately
					// after ControlFunctions.showProductImageMouseover()
					$func.showProductImageMouseover(img);
					$func.hideProductImageMouseover();
				}
				break;
			case "default":
				// Empty and/or couldn't be detected, or a UA-specific custom type.
				if(wWidth < lBreak.minMd) {
					// It's a smaller screen, such as a mobile phone. Use the full screen popup with
					// a maximum image width of 575px.
					$func.openImage($img.data("picture"),500);
				} else if(wWidth > lBreak.minMd && wWidth < lBreak.minLg) {
					// It's a medium screen, such as a tablet. Open the full screen popup with a
					// maximum image width of 575px.
					$func.openImage($img.data("picture"),575);
				} else if(wWidth > lBreak.minLg) {
					// It's a large screen, such as a desktop or laptop that has a touch screen.
					// Use the popup image, which will require clicking/touching elsewhere on the
					// screen to dismiss the popup. This is accomplished with a click handler set
					// in the ControlFunctions.hideProductImageMouseover() that's called immediately
					// after ControlFunctions.showProductImageMouseover()
					$func.showProductImageMouseover(img);
					$func.hideProductImageMouseover();
				}
				break;
		}
	}

	/**
	 * Callback event handler for product image mouse-overs. These only occurs on devices where touch is not
	 * registered as supported. For anything else, click/touch events are supported via @see EventHandlers.productImageTouchStart
	 * @see EventHandlers.productImageTouchStart
	 * @param  {any} e	The mouseover event
	 * @returns void
	 */
	productImageMouseOver(e:any): void {
		e.preventDefault();
		e.stopPropagation();
		let $var = window.ocConVars, $events = window.ocEvents, $func = window.ocControl;
		let img = e.target;
		let lBreak = $var.breakpoints, wWidth = $(window).width();
		if(wWidth > lBreak.minLg) {
			$func.showProductImageMouseover(img);
			$func.hideProductImageMouseover();
		}
	}

	/**
	 * Callback event handler for the custom image mousedown that don't support the PointerEvents API.
	 * @param  {any} e	The mousedown event
	 * @returns void
	 */
	productImageMouseDown(e:any): void {
		let $var = window.ocConVars, $events = window.ocEvents, $func = window.ocControl;
		let img = e.target, $img = $(e.target), pType = e.pointerType;
		let lBreak = $var.breakpoints, wWidth = $(window).width();
		if(wWidth < lBreak.maxMd) {
			// If it's a smaller screen size, such as a handheld device using an
			// external input, or a small-width browser window, don't show the pop-up
			// window. Instead, show the full-screen modal popup type window:
			$func.openImage($img.data("picture"),500);
		} else if(wWidth > lBreak.minLg) {
			// It's a large screen, such as a desktop or laptop that has a touch screen.
			// Use the popup image, which will require clicking/touching elsewhere on the
			// screen to dismiss the popup. This is accomplished with a click handler set
			// in the ControlFunctions.hideProductImageMouseover() that's called immediately
			// after ControlFunctions.showProductImageMouseover(). With a mouse, one can
			// also move the mouse out from overtop the image which will dismiss it. I have
			// yet to be able to successfully emulate this functionality on a mobile device,
			// nor have I found any examples of anyone whom has, including from companies such
			// as Google.
			$func.showProductImageMouseover(img);
			$func.hideProductImageMouseover();
		}
	}

	/**
	 * Callback event handler for the window resize event.
	 * @param  {any} e	The window resize event passed in by the event listener.
	 * @returns void
	 */
	windowResizeEvent(e:any): void {
		// Set the main variables.
		let $var = window.ocConVars, $events = window.ocEvents, $func = window.ocControl;
		// Get the column title bar and it's subrow.
		let $testBar = $("#testtitle"), $isSub = $testBar.find("#gridTitleSubRow2");
		// Call the function setProductTitleWidth from functions.ts
		$func.setProductTitleWidth();

		// Debounce the window resize event so that it's not a constant update.
		/*
		clearTimeout(window.resizeEndEvent);
		window.resizeEndEvent = setTimeout(() => {
		}, 750);
		*/

		if(!$var.detailsShown && $isSub.length === 0) {
			// The subheader does not exist on the page. Update position only.
			$func.setProductSubTitleStart();
		} else if($var.detailsShown && $isSub.length > 0) {
			// The subheader exists on the page. Update details in grid.
			$func.setProductSubTitleStart();
			$func.updateProductDetails(false);
		} else if($var.detailsShown && $isSub.length === 0) {
			// Details is supposed to be shown, but subheader isn't shown.
			// Update subheader and details
			$func.setProductSubTitleStart();
			$func.updateProductSubTitle(0,false);
			$func.updateProductDetails(false);
		}
	}
}