/// <reference path="../../vendor/jqwidgets-scripts/jqwidgets-ts/jqwidgets.d.ts" />
import { InitWidgets } from "./init";
import { ControlFunctions } from "./functions";

$(function(){
	//console.log("entering test.ts");
	//console.log("Creating new GetInfo Instance");
	//var x = new GetInfo2();
	//console.log("Result of creation is: ", x);

	//(<any>$(".mdc-chip-set")).mdcChip();
	//x.chipSet = $(".mdc-chip-set").data("mdcChip");

	let lInit = new InitWidgets;
	let mVars = lInit.mVars;
	let cFuncs = lInit.cFunc;

	let lRowData = {
		Available: "Available",
		BotName: "Agrimonia eupatoria",
		BrandCode: "OCN",
		BrandName: "Bulk Herbs & Spices (Consumer)",
		BrandTitle: "Organic Connections",
		Commodity: "Agrimony",
		Description: "Agrimony Herb C/S",
		DescriptionFull: "Agrimony Herb C/S (Agrimonia eupatoria)",
		Designation: "ORG",
		ItemNumber: "C.500",
		Kosher: "Y",
		MarketingComments: null,
		Size: "1 LB",
		SortAsBulk: "Y",
		StockItem: "Y",
		UpcCode: "628240005007",
		WebCatalog: "Y",
		bBrand: "BULK",
		bComCode: "AGRI-HE-CSO",
		bCommodity: "AGRI",
		picMain: null,
		picThumbnail: null
	};

	lInit.runInit();

	$("#subbrand_wrapper").hide();
	
});