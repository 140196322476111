/// <reference path="../../vendor/jqwidgets-scripts/jqwidgets-ts/jqwidgets.d.ts" />
import { MainVars } from "./vars";
import { ControlFunctions, AsyncFunctions } from "./functions";
import { EventHandlers } from "./events";
import { MyWindow } from "./globals";

declare var window: MyWindow;

export class InitWidgets {

	public mVars = new MainVars;
	public cFunc: ControlFunctions = new ControlFunctions(this.mVars);
	public cEvent: EventHandlers = new EventHandlers(this.mVars);
	public cAsync: AsyncFunctions = new AsyncFunctions();

	/**
	 * Main constructor for catalog.orgcon.ca
	 */
	constructor() {
		// Initialize global variables
		let $var = this.mVars, $func = this.cFunc, $event = this.cEvent, that = this;
		// Set the functions global variable to a new instance of the ControlFunctions class.
		this.cFunc = new ControlFunctions(this.mVars);
		// Set the custom Window objects holding our global class objects
		window.ocConVars = this.mVars;
		window.ocControl = this.cFunc;
		window.ocAsync = this.cAsync;
		window.ocEvents = this.cEvent;
		window.detailsShown = $var.detailsShown;
		window.isBulk = $var.isBulk;

		// Loop through the global pager sizes variable
		$var.pagerSizes.forEach(function(item) {
			// Initialize local variables
			let nItem = null, sel = "";
			// If the current item is the 50 items per page item, populate the selected
			// variable.
			if(item === "25") {
				sel = "selected"
			}
			// Populate the output item variable with the HTML object
			nItem = `<option ${sel} value="${item}">${item}</option>`;
			if(nItem !== null) {
				// If the output item is not null, add it to the number of products per
				// page drop-down box
				$("#set_pager_size").append(nItem);
			}
		});

		// Initialize a new instance of the SelectPicker control on the number of products to show per page
		// select box.
		$("#set_pager_size").selectpicker();

		$func.createListBoxes();

	}

	private async initJqx(): Promise<boolean> {

		let $var = window.ocConVars, $func = window.ocControl, $async= window.ocAsync, $event = window.ocEvents;

		return new Promise<boolean>((resolve, reject) => {
			

			// Intialize the jWidgets Expander control options variable
			let expandOptions: jqwidgets.ExpanderOptions =
			{
				width: "100%",
				height: "auto",
				expanded: false,
				theme: "orgcon"
			};
			// Create the Filter by Brands expander control
			$var.brandHolder = jqwidgets.createInstance("#brandHolder", 'jqxExpander', expandOptions);
			// Create the Filter by Brand-Category expander control
			$var.subBrandHolder = jqwidgets.createInstance("#subbrandHolder", 'jqxExpander', expandOptions);
			// Create the Filter by Herb/Spice expander control
			$var.herbHolder = jqwidgets.createInstance("#commodityHolder", 'jqxExpander', expandOptions);
			// Create the Filter by Item Details expander control
			$var.detailHolder = jqwidgets.createInstance("#itemdetailsHolder", 'jqxExpander', {
				width: "100%",
				height: "auto",
				expanded: true,
				theme: "orgcon"
			});
			// Create the Item Legend Information expander control
			$var.infoHolder = jqwidgets.createInstance("#infoHolder", 'jqxExpander', {
				width: "100%",
				height: "auto",
				expanded: true,
				theme: "orgcon"
			});

			// Create the main grid data adapter
			$var.dataAdapter = new $.jqx.dataAdapter($var.source, {
				beforeSend: (xhr:any, settings: any) => {
					if($var.mainGrid !== null) {
						$var.mainGrid.showloadelement();
					}
				},
				loadComplete: (data:any) => {
					// Anonymous callback for loadComplete event. Currently empty but left in for posterity.
					//console.log("loadComplete: ", data);
					if($var.mainGrid !== null) {
						$var.mainGrid.hideloadelement();
					}
					
					if($var.detailsShown) {
						$func.getLoadedStateAsync().then((val) => {
							$func.updateProductDetails(false);
						});	
					}
				},
				processData: (data: any) => {
					let cPage = $var.dataAdapter.pagenum;
					data.pagenum = $var.dataAdapter.pagenum;
					if($var.isBulk && cPage > 0) {
						data.bulkpage = $var.dataAdapter.pagenum;
					} else if($var.isBulk && cPage === 0) {
						data.bulkpage = 0;
					}

					return data;
				}
			});

			// Initialize the jqWidgets grid control options variable
			let gridOptions: jqwidgets.GridOptions = {
				source: $var.dataAdapter,
				
				enablebrowserselection: true,
				filterable: false,
				showfiltermenuitems: false,

				altrows: true,
				selectionmode: "none",
				
				showheader: false,
				columnsresize: false,
				showrowlines: false,
				showcolumnlines: false,
				showcolumnheaderlines: false,

				groupable: true,
				closeablegroups: false,
				showgroupsheader: false,
				groupsexpandedbydefault: true,
				groupsrenderer: $func.renderGroups,		// Call the ControlFunctions.renderGroups method
				groups: ["BrandTitle", "BrandName"],

				enablehover: false,
				enabletooltips: false,
				enablekeyboarddelete:false,
				autoshowcolumnsmenubutton: false,
				
				rowdetails: false,
				showrowdetailscolumn: false,
				//rowdetailstemplate: { rowdetails: `<div style="margin: 10px"></div>`, rowdetailsheight: 200 },
				//initrowdetails: $func.gridInitDetails,

				width: "100%",
				theme: "orgcon",

				autoheight: true,
				autorowheight: true,
				
				pageable: true,
				pagesize: 25,
				pagesizeoptions: $var.pagerSizes,
				pagerbuttonscount: 5,
				//pagermode: "simple",
				//pagerrenderer: $func.gridRenderPager,
				virtualmode: true,
				rendergridrows: (params) => {
					//console.log(params.data);
					return params.data;
				},

				columns:[
					{ text: "Brand", datafield: "BrandTitle", menu: false, filterable: false, hidden: true, resizable: false },
					{ text: "Category", datafield: "BrandName", menu: false, hidden: true, resizable: false },
					{ text: "Code", datafield: "BrandCode", menu: false, hidden: true, resizable: false },
					{ text: "Commodity", datafield: "Commodity", menu: false, hidden: true, resizable: false },
					{
						text: "Products",
						datafield: "ItemNumber",
						menu: false,
						resizable: false,
						cellsrenderer: $func.renderProduct	// Call the ControlFunctions.renderProduct method
					},
					{ text: "Commodity", datafield: "Description", menu: false, hidden: true, resizable: false },
					//{ text: "Commodity", datafield: "DescriptionFull", menu: false, hidden: true, resizable: false },
				],
				ready: $event.gridReady,		// Call the EventHandlers.gridReady handler on the ready event
				rendered: $event.gridRendered	// Call the EventHandlers.gridRendered handler on the rendered event
			};

			// Create event handler for the jqxGrid bindingcomplete event.
			// This must be performed before the creation of the jqxGrid, otherwise
			// the event won't register or fire properly.
			$("#jqxgrid").on("bindingcomplete", function(event){
				// Call ControlFunctions.initMobileImages() method. This is deprecated in the new version
				// of the catalog, and will be removed completely in a future update.
				//that.initMobileImages();
				// Every time new data gets bound - or in other words, on every single update -
				// call the custom render extension to update cell sizing without re-rendering cell
				// content. This is needed for dynamic element sizing, addition, and deletion.
				//$var.mainGrid.exRenderExpansion();
				// Set the isBindingComplete boolean flag on the jqxGrid to true. For some reason this
				// flag doesn't set itself under normal operations, and needs to be set manually here
				// for other features being used.
				$var.mainGrid.isBindingCompleted = () => {
					return true;
				};
			});

			// Create a new instance of the jqWidgets jqxGrid.
			$var.mainGrid = jqwidgets.createInstance("#jqxgrid", 'jqxGrid', gridOptions);

			resolve(true);
		});
		
	}
	
	/**
	 * Initializes the various page elements. Method is created as async to allow
	 * awaiting further async methods.
	 * @returns Promise<void>
	 */
	public async runInit(): Promise<void> {
		let $vars = window.ocConVars, $func = window.ocControl, $event = window.ocEvents, $async = window.ocAsync;
		//$(".moreInfo")

		await this.initJqx();

		// Create an event handler for our more info buttons.
		//$(document).on("click", ".moreInfo", $event.btnMoreDetailsClick);

		// Check to see if the new PointerEvent API is supported. To allow this in the Typescript
		// typings without it throwing an error, it was required to add the PointerEvent property
		// to the MyWindow interface extension of the Window class, which can be found in the
		// /scripts/ts/globals.ts file.
		if(window.PointerEvent) {
			//console.log("PointerEvent API is supported on this device");
			$("#jqxgrid").on("pointerdown", "img[data-picture]", $event.productImagePointerDown);
			
			if($vars.isTouchSupported) {
				//console.warn("window.PointerEvent && touch :: no additional handlers required");
			} else {
				//console.warn("window.PointerEvent && NOT touch :: registering mouseover handler");
				$("#jqxgrid").on("mouseover", "img[data-picture]", $event.productImageMouseOver);
			}
		} else {
			if($vars.isTouchSupported) {
				//console.log("window.PointerEvent API does not exist on this touch device :: registering mousedown event only");
				$("#jqxgrid").on("mousedown.ocImageSpace", "img[data-picture]", $event.productImageMouseDown);
			} else {
				//console.warn("window.PointerEvent API does not exist on this non-touch device :: registering mouseover event only");
				$("#jqxgrid").on("mouseover", "img[data-picture]", $event.productImageMouseOver);
			}
		}

		// Initialize the chip area above the grid
		(<any>$(".mdc-chip-set")).mdcChip();
		// Ensure that the main global variable is populated with the resulting chipset.
		$vars.chipSet = $(".mdc-chip-set").data("mdcChip");
		// Update the Brands listbox
		//$func.updateBrands();
		await $async.updateBrandsAsync();
		// Update the Brand-Category listbox
		$func.updateSubBrand();
		// Update the Herb/Spice listbox
		$func.updateCommodity();

		// Add an event handler for the Filter by Brand listbox
		$vars.brandBox.addEventHandler("checkChange", $event.listBrandChange);
		// Add an event handler for the Filter by Brand-Category listbox
		$vars.subBrandBox.addEventHandler("checkChange", $event.listSubBrandChange);
		// Add an event handler for the Filter by Herb/Spice listbox
		$vars.herbBox.addEventHandler("checkChange", $event.listHerbChange);
		// Add an event handler for the main grid's page changed event.
		$vars.mainGrid.addEventHandler('pagechanged', this.cEvent.gridPageChanged)

		// Add an event handler for the All Items/Bulk Herbs & Spices filters
		$("input[name=rdoBulk]").on("change", $event.bulkItemChange);
		// Add an event handler for the Show All Details filter button
		$("#showDetails").on("click", $event.showAllDetailsClick);
		// Add an event handler for the Expand All Groups button
		$("#btnExpandAll").on("click", $event.btnExpandClick);
		// Add an event handler for the Collapse All Groups button
		$("#btnCollapseAll").on("click", $event.btnCollapseClick);
		// Add an event handler for the Search (magnifying glass) icon
		$("#searchicon").on("click",this.cEvent.searchClick);
		// Add an event handler for the Clear Search (x) icon
		$("#clearsearch").on("click", this.cEvent.searchClear);
		// Add an event handler for the keyup event of the search input box.
		$("#searchProduct").on("keyup", this.cEvent.searchKeypress);
		// Add an event handler for changing the number of products shown per page.
		$("#set_pager_size").on("changed.bs.select", $event.selectPagerChange);
		// Add an event handler for the filter by organic designation switches.
		$("input[data-action=desFilter]").on("change", $event.checkInfoDesignationChange);
		// Add an event handler for the filter by stock items switch.
		$("#stock").on("change",$event.checkInfoStockChange);
		// Add an event handler for the filter by kosher switch.
		$("#kosher").on("change",$event.checkInfoKosherChange);
		// Add an event handler for while adding a chip to above the grid
		$(".mdc-chip-set").on("mdc.chips.chipAdding", $event.chipsAdding);
		// Add an event handler for adding a chip to above the grid
		$(".mdc-chip-set").on("mdc.chips.chipAdded", $event.chipsAdded);
		// Add an event handler for removing a chip from above the grid
		$(".mdc-chip-set").on("mdc.chips.chipRemoved", $event.chipsRemoved);

		// Add event handler for filters button
		$("#filtersbutton").click($event.navOpen);
		// Add event handler for filters override button in navigation bar
		$(".filterOverride").click($event.navOpen);
		// Add event handler for filters close button
		$(".closebtn").click($event.navClose);
		// Add event handler for return to top button.
		$("#topButton").click($event.topFunction);

		// Create an event handler for the keyup event on the base document, pointing to the EventHandlers.closeImage
		// handler
		$(document).on("keyup", $event.closeImage);

		// Call the local method getLocationTimeout with a 1000 millisecond interval
		$func.getLocationTimeout(1000, $func.getLocationArguments);

		// Create an event hander for the scroll method, specifically calling the passive property.
		// This prevents blocking and timeouts, increasing performance especially on mobile devices.
		window.addEventListener("scroll", (e) => {
			$event.scrollFunction();
		}, { passive: true});

		window.addEventListener("resize", (e) => {
			$event.windowResizeEvent(e);
		}, {passive: true });

		$event.windowResizeEvent(null);

		$func.setProductSubTitleStart();
	}	
}