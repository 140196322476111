/// <reference path="../../vendor/jqwidgets-scripts/jqwidgets-ts/jqwidgets.d.ts" />
/// <reference path="./orgcon.d.ts" />
import { MainVars } from "./vars";
import { MyWindow } from "./globals";

declare var window: MyWindow;

export class ControlFunctions {
	public mVars: MainVars;

	/**
	 * Holds all main methods for Organic Connections online catalog
	 * @param  {MainVars} mVars
	 */
	constructor(mVars: MainVars){
		// Assign the internal main variables array to the incoming variable.
		this.mVars = mVars;
	}

	/**
	 * Callback method for the jqxGrid initDetails method. Currently not used but left for possible future expansion.
	 * @param  {any} index				The row index
	 * @param  {any} parentElement		The parent HTML element
	 * @param  {any} gridElement		The grid element
	 * @param  {any} datarecord			The bound data for the row
	 */
	public gridInitDetails(index: any, parentElement: any, gridElement: any, datarecord: any) {

	}

	/**
	 * Callback method for the jqxGrid PagerRenderer method. Renders a custom pager on the bottom of the grid.
	 */
	public gridRenderPager() {
		let $var = window.ocConVars, $fun = window.ocControl, $event = window.ocEvents, $grid = $var.mainGrid;
		
		let element = null, rCount = null, dview = null, tGroups = null, groupCount = 0, tRecords = 0, pageNum = 0;
		if($grid != null) {
			element = $grid.exInitPager();
			rCount = $var.mainGrid.getdatainformation().rowscount;
			dview = $grid.exGetDataView();

			tGroups = dview.loadedgroups;
			groupCount = 0;
			for(let i = 0; i < tGroups.length; i++) {
				if(tGroups[i].hasOwnProperty("group") && (tGroups[i].group !== null && tGroups[i].group !== "")) {
					//console.log(tGroups[i]);
					groupCount++;
				}
			}
			tRecords = dview.totalrecords - groupCount;
			pageNum = dview.pagenum * dview.pagesize;

			//$var.pagerSizes.push(rCount);
			//$var.mainGrid.pagesizeoptions = $var.pagerSizes;
		}

		return (<any>element);
		
	}

	/**
	 * Callback method for the jqxGrid control responsible for rendering a grouped row header
	 * or sub-group header.
	 * @param  {string} text	The text for the title coming from the jqxGrid
	 * @param  {any} group		The group from jqxGrid
	 * @param  {any} expanded	The expanded state of the group from jqxGrid
	 * @param  {any} data		The data from jqxGrid
	 */
	public renderGroups(text: string, group: any, expanded: any, data:any) {
		// Initialize the control variables
		let $var = window.ocConVars;
		// Initialize the internal variables
		let title: string, brandTitle: string, commTitle: string;

		// Check to see if the grouping column title "Brand" exists
		if(text.indexOf("Brand: ") > -1) {
			// If so, split the string by the ": ", and assign just the Brand name to the variable.
			title = text.split(": ")[1];
			if(!$var.isBulk) {
				// If the page is not in bulk mode, immediately return the dark green group to the calling method
				return `<div class="jqx-grid-groups-row jqx-grid-groups-row-bootstrap" style="position: absolute; top: 8px; width: 100%; height: 100%; background-color: #234331;"><span style="display: inline-block; margin-left: 10px; margin-top:0.5rem; color: #fff;">${title}</span></div>`;
			} else {
				// If it is bulk, return a slightly darker, mostly transparent green group to the calling method
				return `<div class="jqx-grid-groups-row jqx-grid-groups-row-bootstrap" style="position: absolute; top: 8px; width: 100%; height: 100%; background-color: rgba(62, 117, 86, 0.2);"><span style="display: inline-block; margin-left: 10px; margin-top:0.5rem; color: #000; font-weight: bold; font-size: 1.05rem;">${title}</span></div>`;
			}
		}
		// Check to see if the sub-grouping column title "Category" exists
		if(text.indexOf("Category: ") > -1) {
			// If it does, check to see if the sub-group has the brand name appended to it, as is the case in
			// some Celebration Herbals products, and strip the first section of the string off.
			brandTitle = text.split(": ")[1];
			if(!$var.isBulk) {
				// If the page is not in bulk mode, immediately return a lighter green group to the calling method
				return `<div class="jqx-grid-groups-row jqx-grid-groups-row-bootstrap" style="position: absolute; top: 8px; width: 100%; height: 100%; background-color: #3e7556;"><span style="display: inline-block; margin-left: 10px; margin-top:0.5rem; color: #fff;">${brandTitle}</span></div>`;
			} else {
				// If it is bulk, return a light green, transparent group to the calling method
				return `<div class="jqx-grid-groups-row jqx-grid-groups-row-bootstrap" style="position: absolute; top: 8px; width: 100%; height: 100%; background-color: rgba(62, 117, 86, 0.0); border-bottom: 1px solid;"><span style="display: inline-block; margin-left: 10px; margin-top:0.5rem; color: #000; font-weight: bold; font-size: 1.02rem;">${brandTitle}</span></div>`;
			}
		}
		if(text.indexOf("Commodity: ") > -1) {
			// This is the default display for bulk items, as they get grouped by herb/spice type rather than by brand/sub-category.
			// Ensure only the commodity itself is selected to display.

			let subItem = data.subItems[0],
				mouseTitle = "",
				titleOutput = "";

			if(subItem.hasOwnProperty("CommodityNotes")) {
				mouseTitle = subItem.CommodityNotes;
			}

			if(mouseTitle !== null && mouseTitle !== "null" && mouseTitle !== "") {
				titleOutput = `title="${mouseTitle}"`;
				//console.log("Output is: ", titleOutput);
			}

			commTitle = text.split(": ")[1];
			if(commTitle === "") {
				// If the commodity is empty, return "N\A" to the calling method
				//return `<div class="jqx-grid-groups-row jqx-grid-groups-row-bootstrap" style="position: absolute; top: 8px; width: 100%; height: 100%; background-color: #234331;"><span style="display: inline-block; margin-left: 10px; margin-top:0.5rem; color: #fff; font-weight: bold; font-size: 1rem;">N\\A</span></div>`;
				return null;
			}
			// Return a dark green group with the commodity to the calling method.
			return `<div class="jqx-grid-groups-row jqx-grid-groups-row-bootstrap" style="position: absolute; top: 8px; width: 100%; height: 100%; background-color: #234331;"><span style="display: inline-block; margin-left: 10px; margin-top:0.5rem; color: #fff; font-weight: bold; font-size: 1rem;" ${titleOutput}>${commTitle}</span></div>`;
		}
	}

	/**
	 * Callback method for the jqxGrid control to render a single cell. This in turn will call either the
	 * @see ControlFunctions.renderConsumer or @see ControlFunctions.renderBulk methods to render out an
	 * entire product item listing.
	 * @param  {any} _row				The incoming row index from the jqxGrid
	 * @param  {any} _column			The incoming column from the jqxGrid
	 * @param  {any} _value				The incoming value from the jqxGrid
	 * @param  {any} _parentElement		The incoming HTML parent element for the given cell
	 * @param  {any} _colProperties		The incoming properties for the jqxGrid column
	 * @param  {any} rowData			The bound data for the row the cell belongs to
	 */
	public renderProduct = function(_row: any, _column: any, _value: any, _parentElement: any, _colProperties: any, rowData: any)
	{
		// Intialize the control variables; we only need the global variables and functions file here.
		let $var = window.ocConVars, $func = window.ocControl;
		// Check if display bulk items is set to off; this is the default value
		if(!$var.isBulk) {
			// Call the renderConsumer method with the row's bound data and index, and return the results.
			return $func.renderConsumer(rowData, _row);
		} else if($var.isBulk) {
			// Call the renderBulk method with the row's bound data and return the results.
			/*console.log("_row: ", _row);
			console.log("_column: ", _column);
			console.log("_value: ", _value);
			console.log("_parentElement: ", _parentElement);
			console.log("_colProperties: ", _colProperties);
			console.log("rowData: ", rowData);*/
			return $func.renderBulk(rowData, _row);
		} else {
			// Somehow the state has been lost.
			throw new Error("Can't retrieve products!");
			return "";
			//console.error("Can't retrieve products");
		}
		
	}

	/**
	 * Renders a single product listing in the consumer ("All Products") listing format.
	 * @param  {rowData} rowData		The bound data for the product to render
	 * @param  {number} rowIndex?		The row index for the product to render
	 * @see ControlFunctions.renderProduct
	 * @see ControlFunctions.renderBulk
	 * @see DisplayFunctions
	 * @returns string
	 */
	public renderConsumer(rowData: rowData, rowIndex?: number): string
	{
		// Initalize a new reference to the DisplayFunctions class.
		let $nF = new DisplayFunctions;
		// Initialize the extreme outer container for the product; needed to ensure boundaries are obeyed
		let container = `<div class="row jqxRowOverride" style="width: 100%">`;
			// Initialize the inside outer container for the product; needed to ensure boundaries are obeyed
			let item = `<div class="col jqxColOverride" style="width: 100%;">`;
				// Initialize the main container wrapper that initializes the product in a column format
				let info = `<div class="d-flex flex-column" style=" height: 100%;">`;
					// Initialize the product listing effect wrapper that gives it the drop shadow
					info += `<div class="bulkcell">`;
						// Initailize the product table wrapper.
						let nTableWrap = `<div class="tableWrap">`;
							// Initialize the product table
							let nTable = `<div class="itemTable">`;

								let pWrap = `<div class="picWrap">`;

								//#region Product Details Wrapper
								
								// Initialize the product details wrapper
								let iWrap = `<div class="itemWrap">`;
									//#region Header Area
									// Create the main header row. The description row is hidden initially via the CSS rules found in
									// /scss/_cellrender.scss, while the second and third cells have their titles automatically populated
									// depending on screen size, also using CSS rules found in /scss/_cellrender.scss.
									let headRow = `<div class="headRow mainHead">`;
										headRow += `<div>Description</div>`;
										headRow += `<div></div>`;
										headRow += `<div></div>`;
										headRow += `<div>Pack Size</div>`;
										headRow += `<div>Available?</div>`;
									headRow += `</div>`;
									// Add the main header row to the product details wrapper.
									//iWrap += headRow;
									//#endregion Header Area
									//#region Item Area
									// Create the basic details row. The description ceel uses CSS magic found in /scss/_cellrender.scss
									// to ensure it's the full width of the listing on smaller screens, or is inline with the rest of the
									// row on larger screens. The description and item number are directly inserted into the information,
									// where the rest of the basic information is retrieved via external functions found in the DisplayFunctions
									// class
									let itemRow = `<div class="itemRow basicRow" data-rowindex="${rowData.uid}">`;
										itemRow += "<div></div>";
										if(typeof rowData.CommodityNotes !== "undefined" && rowData.CommodityNotes !== null && rowData.CommodityNotes !== "") {
											// When CommodityNotes is ready to be added in to the mouse-over titles, uncomment the following line,
											// and remove the line after it.
											//itemRow += `<div class="titleRow" title="${rowData.CommodityNotes}">${rowData.DescriptionFull}</div>`;
											itemRow += `<div class="titleRow">${rowData.DescriptionFull}</div>`;
										} else {
											itemRow += `<div class="titleRow">${rowData.DescriptionFull}</div>`;
										}
										
										itemRow += `<div>${rowData.ItemNumber}</div>`;
										itemRow += `<div>${$nF.getTextDesignation(rowData.Designation)}</div>`;
										itemRow += `<div>${$nF.getSize(rowData.Size)}</div>`;
										itemRow += `<div>${$nF.getAvailable(rowData.Available)}</div>`;
									itemRow += `</div>`;
									// Add the basic details to the product details wrapper.
									iWrap += itemRow;
									//#endregion Item Area

								iWrap += `</div>`;
								// Add the product details wrapper to the main table.
								//nTable += iWrap;
								pWrap += iWrap;
								//#endregion Product Details Wrapper

								//#region Picture Column
								// Call the DisplayFunctions.getPic method with the thumbnail and hi-res picture values from bound row data,
								// and initialize a variable with the result of the method call. If a picture isn't found, the result of the call
								// will be null or empty.
								let picCol = $nF.getPic(rowData.picThumbnail, rowData.picMain);
								// Check to see if the picCol result was null or empty
								if(picCol !== null && picCol !== "") {
									// If it wasn't null or empty, add it to the main table 
									//nTable += picCol;
									pWrap += picCol;
								}
								//#endregion Picture Column

								pWrap += `</div>`;
								nTable += pWrap;

								//#region Comment and Show More Details row
								// Initialize the comment variable. The actual comment is retrieved from DisplayFunctions.getComments and
								// returns null or empty if no comment is retrieved. 
								let pComment = $nF.getComments(rowData.MarketingComments);
								// Create the actual comment row with the value of the comment variable. The Show All Details button
								// is now depreciated but was created with the rowIndex passed into this method from
								// ControlFunctions.renderProduct.
								let comRow = `<div class="itemRow commentRow">`;
									comRow += `<div class="comCell">${pComment}</div>`;
									//comRow += `<div class="btnCell"><span class="moreInfo" data-rowindex="${rowIndex}" data-status="hidden">Show All Details <i class="fas fa-caret-down"></i></span></div>`;
								comRow += `</div>`;

								// Check to see if the returned comment is not null and not empty
								if(pComment !== null && pComment !== "") {
									// If not, add the comment row to the main table. Placing it here ensures it's always below the
									// the product details information as well as the item picture, which will appear inline
									// with the product information itself, or directly under and to the right of it on smaller
									// screens.
									nTable += comRow;
								}
								
								//#endregion Comment and Show More Details row
							// Close the main product table
							nTable += `</div>`;
							// Add the main table to the table wrapper
							nTableWrap += nTable;
						// Close the main table wrapper.
						nTableWrap += `</div>`;
						// Add the main table wrapper to the product listing wrapper
						info += nTableWrap;
					// Close the product listing wrapper
					info += "</div>";
				// Close the main container wrapper
				info += "</div>";
				// Add the main container wrapper to the outer container.
				item += info;
			// Close the inside outer container
			item += `</div>`;
			// Add the inside outer container to the extreme outer container
			container += item;
		// Close the extreme outer container
		container += "</div>";
		// Return the entire finished element back to the ControlFunctions.renderProduct method for return to the jqxGrid control
		return container;
	}

	/**
	 * Renders a single product listing in the bulk ("Bulk Herbs & Spices Only") listing format
	 * @param  {any} rowData			The bound data for the product to render
	 * @param  {number} rowIndex?		The row index for the product to render
	 * @see ControlFunctions.renderProduct
	 * @see ControlFunctions.renderConsumer
	 * @see DisplayFunctions
	 * @returns string
	 */
	public renderBulk(rowData:rowData, rowIndex?: number): string {
		// Initalize a new reference to the DisplayFunctions class.
		let $nF: DisplayFunctions = new DisplayFunctions;

		if(!rowData.hasOwnProperty("Commodity")) {
			//console.warn("ERROR! RECORD SHOULDN'T BE HERE!", rowData);
		}

		//console.log(rowData);

		// Open product container
		let container = `<div class="row jqxRowOverride" style="width: 100%; margin: 0;">`;

			// Create main product column
			let item = `<div class="col jqxColOverride" style="width: 100%; padding: 0;">`;

				// Open main product grouping wrapper
				let info = `<div class="d-flex flex-column" style="height: 100%;">`;
					info += `<div class="bulkWrapper">`;
						//info += `<div class="mt-2 mb-4" style="font-weight: bold; font-size: 1.5rem;"><span style="text-decoration: underline;" title="${rowData.DescriptionFull}">${rowData.DescriptionFull}<span></div>`;
						// Create bulk grouping title with short description
						//console.log("Bulk row data: ", rowData);
						let mouseTitle = "",
							titleOutput = "";

						if(rowData.hasOwnProperty("CommodityNotes")) {
							mouseTitle = rowData.CommodityNotes;
						}

						if(typeof mouseTitle != "undefined" && mouseTitle !== null && mouseTitle !== "null" && mouseTitle !== "") {
							titleOutput = `title="${mouseTitle}"`;
							//console.log(`Output for ${rowData.Description} is: `, titleOutput);
						}
						info += `<div class="mt-2 mb-2" style="font-weight: bold; font-size: 1.1rem;" ${titleOutput}><span>${rowData.Description}<span></div>`;

						if(typeof(rowData.Products) !== "undefined")
						{
							// initialize a variable to hold the number of products found in this grouping.
							// Note that as with all arrays, it is 0 indexed.
							let pLength = rowData.Products.length;
							//console.log(rowData.Products);
							for(let cBrand in rowData.Products) {

								//console.log(cBrand);

								info += `<div class="mt-1" style="background-color: #3e7556; color: #FFFFFF; padding: 0.25rem; text-indent: 0.5rem; font-weight: bold; font-size: 0.8rem;"><span title="${cBrand}">${cBrand}</span></div>`;

								let prodGroup = (<any>rowData.Products[cBrand]);
								//console.log(typeof prodGroup);
								//console.log(prodGroup.length);
								// Loop through the product array
								prodGroup.forEach((product, cIndex) => {
									//console.log(product, cIndex);

									// Start bulkcell wrapper
									if(cIndex === pLength - 1) {
										// If this is the last product in the group, make the bottom margin
										// bootstrap class the mb-1 class as it doesn't need as large of a
										// bottom margin; note that it compares the current index to the
										// length of the array minus one. This is because of the 0 index.
										info += `<div class="mb-1 bulkcell">`;
									} else {
										// If it's not the last product, it needs a slightly larger margin in
										// between the products, so that the cells don't appear to overlap
										// slightly, so give it a bootstrap mb-2 class. With the recent reduction
										// in the drop-shadow the cells have, this is more than enough.
										info += `<div class="mb-2 bulkcell">`;
									}

									// Initialize a constant variable to hold a type regular expression comparison string.
									const regex = /\(Consumer\)|\(Industrial\)/gm;
									// Initialize a variable and populate it with the industrial/consumer type
									let iType = product.BrandName
									// Check to see if the type matches the regular expression string
									if(product.BrandName.match(regex))
									{
										// Remove the opening bracket from the string
										iType = iType.split("(")[1];
										// Remove the closing bracket from the string
										iType = iType.split(")")[0];
									}

									//#region Product Table Wrapper
									// Initialize the outer table wrapper
									let nTableWrap = `<div class="tableWrap">`;

										// Initialize the individual product table wrapper
										let nTable = `<div class="itemTable">`;

											let pWrap = `<div class="picWrap">`;

											// Initialize the item row wrapper
											let iWrap = `<div class="itemWrap">`;
											
												//#region Main Header Row
												// Initialize the header row :: DEPRECIATED
												let headRow = `<div class="headRow mainHead">`;
													headRow += `<div>Description</div>`;
													headRow += `<div></div>`;
													headRow += `<div></div>`;
													headRow += `<div>Pack Size</div>`;
													headRow += `<div>Available?</div>`;
												headRow += `</div>`;
												// Add the header row to the item wrapper :: DEPRECIATED
												//iWrap += headRow;
												//#endregion Main Header Row

												//#region Basic Details Row
												let itemRow = `<div class="itemRow basicRow" data-rowindex="${rowIndex}" data-productindex="${cIndex}" data-productbrand="${cBrand}">`;
													//itemRow += `<div class="titleRow">${product.DescriptionFull}</div>`;
													itemRow += "<div></div>";
													if(typeof product.CommodityNotes !== "undefined" && product.CommodityNotes !== null && product.CommodityNotes !== "") {
														// When CommodityNotes is ready to be added in to the mouse-over titles, uncomment the following line,
														// and remove the line after it.
														//itemRow += `<div class="titleRow" title="${product.CommodityNotes}">${product.DescriptionFull}</div>`;
														itemRow += `<div class="titleRow">${product.DescriptionFull}</div>`;
													} else {
														itemRow += `<div class="titleRow">${product.DescriptionFull}</div>`;
													}
													itemRow += `<div>${product.ItemNumber}</div>`;
													itemRow += `<div>${$nF.getTextDesignation(product.Designation)}</div>`;
													itemRow += `<div>${$nF.getSize(product.Size)}</div>`;
													itemRow += `<div>${$nF.getAvailable(product.Available)}</div>`;
												itemRow += `</div>`;
												iWrap += itemRow;
												//#endregion Basic Detals Row
											// Close the item wrapper
											iWrap += `</div>`;
											// Add the item wrapper to the item table
											//nTable += iWrap;
											pWrap += iWrap;

											//#region Picture Column
											// Get the picture column from the DisplayFunctions.getPic method, using the product's
											// thumbnail and hi-res picture paths from the database.
											let picCol = $nF.getPic(product.picThumbnail, product.picMain);
											// Check to see if a picturewas returned
											if(picCol !== null && picCol !== "") {
												// If so, add the picture column to the item table
												//nTable += picCol;
												pWrap += picCol;
											}
											//#endregion Picture Column

											pWrap += `</div>`;
											nTable += pWrap;

											//#region Comments Row

											// Initialize a comment variable and populate it using the DisplayFunctions.getComments
											// method.
											let pComment = $nF.getComments(product.MarketingComments);
											// Create the actual comments row.
											let comRow = `<div class="itemRow commentRow">`;
												comRow += `<div class="comCell">${pComment}</div>`;
												//comRow += `<div class="btnCell"><span class="moreInfo" data-rowindex="${rowIndex}" data-productindex="${cIndex}" data-status="hidden">Show All Details <i class="fas fa-caret-down"></i></span></div>`;
											comRow += `</div>`;
											// Check to see if a comment was retrieved for the current product
											if(pComment !== null && pComment !== "") {
												// If so, add the comment row to the item table.
												nTable += comRow;
											}
											
											//#endregion Comments Row

										// Close the item table.
										nTable += `</div>`;
										// Add the item table to the outer table wrapper
										nTableWrap += nTable;
									// Close the outer table wrapper
									nTableWrap += `</div>`;
									//#endregion Product Table Wrapper
									// Add the product table wrapper to the main grouping table.
									info += nTableWrap;
									// Close bulkcell wrapper
									info += `</div>`;
									
								});
							}
						}

					info += `</div>`;
				// Close main product grouping wrapper
				info += "</div>";
				// Add product grouping wrapper to product column
				item += info;
			// Close product column
			item += `</div>`;
			// Add product column to main product container
			container += item;
		// Close product container
		container += "</div>";
		return container;
	}

	/**
	 * DEPRECIATED
	 * Initialize the picture icon for use on mobile screens. This is being done away with in
	 * favor of the mouse over and possibly touch start/end event handlers on the product thumbnail.<br>
	 * TODO: If this gets brought back, it should be re-written to use event delegation instead of control
	 * loops.
	 * @returns void
	 */
	public initMobileImages(): void {
		// Initialize control variables
		let $var = window.ocConVars, $func = window.ocControl, $event = window.ocEvents;
		// Get reference to the mobile picture image link.
		let tImgs: any = document.getElementsByClassName("imglink");
		// Loop through all the images
		for(let timg of tImgs){
			// Create an event handler on each image using an inline anonymous callback method
			$(timg).on("click", (e) => {
				// Prevent the default action
				e.preventDefault();
				// Stop event from bubbling up the DOM tree.
				e.stopImmediatePropagation();
				// Get the image path from the mobile link
				let dataLink = $(timg).data("link");
				// Call the ControlFunctions.openImage handler with the image path.
				$func.openImage(dataLink);
			});
		}
	}

	/**
	 * Creates the list boxes containing the filter by brand, brand-category,
	 * and herb/spice lists.
	 * @returns void
	 */
	public createListBoxes(): void {
		// Intialize our control variables for the main functions
		let $vars = window.ocConVars, $func = window.ocControl, $event = window.ocEvents;
		// Create a new instance of a jqxListbox control and assign it to the global brand
		// filter box variable.
		$vars.brandBox = jqwidgets.createInstance("#tBrandBox", 'jqxListBox', {
			checkboxes: false,
			disabled: true,
			autoHeight: true,
			width: "100%",
			theme: "orgcon"
		});
		// Create a new instance of a jqxListBox control and assign it to the global
		// brand-category filter box variable
		$vars.subBrandBox = jqwidgets.createInstance("#subbrand", 'jqxListBox', {
			checkboxes: false,
			disabled: true,
			autoHeight: true,
			width: "100%",
			theme: "orgcon"
		});
		// Create a new instance of a jqxListBox control and assign it to the global
		// herb/spice filter box variable
		$vars.herbBox = jqwidgets.createInstance("#commodity", 'jqxListBox', {
			checkboxes: false,
			disabled: true,
			autoHeight: true,
			width: "100%",
			theme: "orgcon"
		});
	}

	/**
	 * Updates the Filter by Brand list box when all items/bulk items has changed.
	 * @returns void
	 */
	public updateBrands(): void {
		let $var = window.ocConVars, $func = window.ocControl, $event = window.ocEvents, $grid = $var.mainGrid, $box = $var.brandBox;

		if($var.chipSet.chipExists("brands", true)) {
			$var.chipSet.removeChip("brands", true);
		}

		$func.loadBrandDataTypes();

		//let tCheck = $box.getCheckedItems(), checkedItems = tCheck === null ? [] : tCheck, tHolder: any = [];

		// Create the Filter by Brand listbox data adapter
		$var.brandAdapter = new $.jqx.dataAdapter($var.brandSource, {
			uniqueDataFields: ["BrandTitle"],
			autoBind: true,
			loadComplete: (_data:any) => {
				// Anonymous callback for loadComplete event. Responsible for updating the list box properly
				// Initialize local variables
				var tCheck: boolean = true, tDisable: boolean = false, tSource: { BrandTitle: string; }[], tGroup: string;

				if($var.brandAdapter.records.length === 0) {
					// If no records were returned, update the local variables to reflect as much
					tSource = [{
						BrandTitle: "None"
					}];
					tGroup = "";
					tCheck = false;
					tDisable = true;
				} else {
					// If records were returned, set the local source toi be the returned records,
					// the group to be by the brand title (organization name), checkmarks enabled, and
					// box disabled to be false.
					tSource = $var.brandAdapter.records;
					tGroup = "BrandTitle";
					tCheck = true;
					tDisable = false;
				}
				// Create the jqWidgets list box using the defined local variables
				$var.brandBox = jqwidgets.createInstance("#tBrandBox", 'jqxListBox', {
					source: tSource,
					displayMember: "BrandTitle",
					valueMember: "BrandTitle",
					checkboxes: tCheck,
					disabled: tDisable,
					autoHeight: true,
					width: "100%",
					theme: "orgcon"
				});
			}
		});
	}

	/**
	 * Updates the Filter by Brand-Category list box when other filters have been selected.
	 * @returns void
	 */
	public updateSubBrand(): void {
		// Intialize the control variables
		let $var = window.ocConVars, $func = window.ocControl, $event = window.ocEvents, $grid = $var.mainGrid, $box = $var.brandBox;
		
		// Check to see if the chip already exists above the grid
		if($var.chipSet.chipExists("subbrands", true)) {
			// Remove it if it does.
			$var.chipSet.removeChip("subbrands", true);
		}
		
		$func.loadSubDataTypes();

		let tCheck = $box.getCheckedItems(), checkedItems = tCheck === null ? [] : tCheck, tHolder: any = [];

		// Create a new jqWidgets.dataAdapter object using the subbrand source object in the
		// main variables file
		$var.subBrandAdapter = new $.jqx.dataAdapter($var.subcatSource,{
			uniqueDataFields: ["BrandTitle", "BrandName"],
			autoBind: true,
			loadComplete: (_data: any) => {
				// Initialize local variables inside loadComplete callback method
				let tCheck:boolean = true, tDisable:boolean = false, tSource: { BrandTitle: string; BrandName: string; }[], tGroup: string;

				let uRecords: any = [];
				tHolder = $var.subBrandAdapter.records;

				tHolder = $var.subBrandAdapter.records.filter((item: SubBrandType) => {
					if(item.BrandName !== null && item.BrandName !== "") {
						uRecords.push(item);
					}
				});


				// Check to see if any records were returned from the server
				if(uRecords.length === 0) {
					// If not, update our local variables with the appropriate data values,
					// including manually giving our source object values of "None".
					tSource = [{
						BrandTitle: "None",
						BrandName: "None"
					}];
					tGroup = "";
					tCheck = false;
					tDisable = true;
				} else {
					// If records were returned from the server, populate the local source
					// object with the records, and assign the rest of the variables.
					tSource = uRecords;
					tGroup = "BrandTitle";
					tCheck = true;
					tDisable = false;
				}

				// Create or recreate the instance of the jqxListBox with the values that were established
				// above, and assign it to the variable in the main variables file.
				$var.subBrandBox = jqwidgets.createInstance("#subbrand", 'jqxListBox', {
					source: tSource,
					groupMember: tGroup,
					displayMember: "BrandName",
					valueMember: "BrandName",
					checkboxes: tCheck,
					disabled: tDisable,
					autoHeight: true
				});

				if(!$var.isBulk) {
					if(checkedItems.length === 1) {
						let base:SubBrandType = $var.subBrandAdapter.records[0];
						if(base.BrandName === null || base.BrandName === "" || typeof base.BrandName === "undefined") {
							$grid.cleargroups();
							// Add the brand title group to the grid
							$grid.addgroup("BrandTitle");
							$grid.render();
						} else {
							$grid.cleargroups();
							// Add the brand title group to the grid
							$grid.addgroup("BrandTitle");
							// Add the brand name group to the grid
							$grid.addgroup("BrandName");
							$grid.render();
						}
					} else {
						$grid.cleargroups();
						// Add the brand title group to the grid
						$grid.addgroup("BrandTitle");
						// Add the brand name group to the grid
						$grid.addgroup("BrandName");
						$grid.render();
					}
				}
			}
		});

		
	}

	/**
	 * Updates the Filter by Herb/Spice list box when other filters have been selected.
	 * @returns void
	 */
	public updateCommodity(): void {
		// Initialize the control variables.
		let $var = window.ocConVars, $func = window.ocControl, $event = window.ocEvents, $grid = $var.mainGrid;
		// Check to see if the chip already exists above the grid
		if($var.chipSet.chipExists("commodity", true)) {
			// And remove it if it does.
			$var.chipSet.removeChip("commodity", true);
		}
		$func.loadHerbDataTypes();

		// Create a new jqWidgets.dataAdapter object using the commodity source object in the
		// main variables file
		var commodityAdapter = new $.jqx.dataAdapter($var.commSource, {
			uniqueDataFields: ["Commodity"],
			autoBind: true,
			loadError: (_data: any) => {
				let data = null;
				// if(_data.hasOwnProperty("responseText")) data = JSON.parse(_data.responseText);

				// if(data.hasOwnProperty("result") && data.result.hasOwnProperty("message") && data.result.message === "No commodities found") {

				// }
				console.log(_data);
			},
			loadComplete: (_data: any) => {

				// Initialize the variables local to the loadComplete callback
				let uRecords:any = [],
					tSource: any[] | {
						Commodity: string;
					}[],
					tCheck: boolean = true,
					tDisable:boolean = false,
					tFilter:boolean = true;
				
				// Filter through the returned records and remove anything that
				// is null or empty.
				commodityAdapter.records.filter(function(item){
					if(item.Commodity !== null)
					{
						uRecords.push(item);
					}
				});	

				// Check the filtered records length to see if there are any records in it
				if(uRecords.length === 0) {
					// If there aren't, assign the variables as appropriate.
					tSource = [{
						BrandTitle: "None",
						BrandName: "None",
						Commodity: "None"
					}];
					tCheck = false;
					tDisable = true;
					tFilter = false;
				} else {
					// If there are records, ensure that they're sorted alphabetically and assign them
					// to the source variable, and set the remaining needed variables.
					tSource = uRecords.sort(function(a, b)
					{
						let commA = a.Commodity.toLowerCase(), commB = b.Commodity.toLowerCase();
						if(commA < commB) return -1;
						if(commA > commB) return 1;
						return 0;
					});
					tCheck = true;
					tDisable = false;
					tFilter = true;
				}
				

				// Create or recreate a new jqxListBox instance and assign it to the variable in the
				// main variables file.
				$var.herbBox = jqwidgets.createInstance("#commodity", 'jqxListBox', {
					source: tSource,
					displayMember: "Commodity",
					valueMember: "Commodity",
					checkboxes: tCheck,
					disabled: tDisable,
					autoHeight: true,
					filterable: tFilter
				});
			}
		});
	}

	/**
	 * Updates the grid to show bulk/industrial items instead of all items
	 * @param  {string} id	The type to switch to. Can be ShowBulk or ShowConsumer
	 * @returns void
	 */
	public showBulk(id: string): void {
		// Intialize control variables
		let $vars = window.ocConVars, $func = window.ocControl, $grid = $vars.mainGrid;

		// Check the incoming parameter to see if it's switching to bulk
		if(id === "ShowBulk") {
			// Add a chip for bulk only
			$vars.chipSet.addChip({title: "Bulk Only", chipType: "bulk"});
			// Set the global isBulk variable to true.
			$vars.isBulk = true;
			// Set the main grid's bulk flag to yes
			$vars.source.data.bulk = "y";
			// Hide the Filter by Brand box
			//$("#brand_wrapper").hide();
			// Hide the Filter by Brand-Category box
			$("#subbrand_wrapper").hide();
			// Clear all groups from the grid
			$grid.cleargroups();
			// Add the Commodity group to the grid
			$grid.addgroup("Commodity");
			if($vars.detailsShown) {
				$vars.detailsShown = false;
				$func.updateProductTitle(2, true);
				$func.updateProductDetails(true);
			}
		} else if(id === "ShowConsumer") {
			// If the incoming parameter is switching to consumer/"all items"
			if($vars.chipSet.chipExists("bulk", true)) {
				// If there's a bulk chip, remove it
				$vars.chipSet.removeChip("bulk",true, false);
			}
			// Set the global isBulk variable to false
			$vars.isBulk = false;
			// Set the main grid's blk flag to no
			$vars.source.data.bulk = "n";
			// Clear all groups from the grid
			$grid.cleargroups();
			// Add the brand title group to the grid
			$grid.addgroup("BrandTitle");
			// Add the brand name group to the grid
			$grid.addgroup("BrandName");
			// Show the Filter by Brand box
			$("#brand_wrapper").show();
			if($vars.detailsShown) {
				$vars.detailsShown = false;
				$("#showDetails").html(`Show All Details <i class="fas fa-caret-down"></i>`);
				$func.updateProductTitle(2, true);
				$func.updateProductDetails(true);
			}
		}

		window.isBulk = $vars.isBulk;

		// Make sure the search box's value is empty; searches are cleared on certain
		// major filter changes like this.
		$("#searchProduct").val(null);
		// Call the ControlFunctions.doSearch() method with a null value, effectively
		// clearing the search.
		$func.doSearch(null);

		// Set all the grid's main flags to the default settings; that is, searching for
		// absolutely all products, nothing filtered.
		$vars.source.data.designations = ["all"];
		$vars.source.data.subbrands = ["all"];
		$vars.source.data.kosher = "";
		$vars.source.data.commodities = ["all"];
		$vars.source.data.subbrands = ["all"];
		$vars.source.data.brands = ["all"];
		$vars.source.data.stock = "";

		// Uncheck all items in Filter by Brand
		$vars.brandBox.uncheckAll();
		// Uncheck all items in Filter by Brand-Category
		$vars.subBrandBox.uncheckAll();
		// Uncheck all items in Filter by Herb/Spice
		$vars.herbBox.uncheckAll();

		// Uncheck the Filter by Kosher switch
		$("#kosher").prop("checked",false);
		// Uncheck the Filter by Stock Item switch
		$("#stock").prop("checked",false);
		// Uncheck the Filter by Organic Designation switches
		$("input[data-action=desFilter]").prop("checked",false);

		// Update the main grid's data
		$grid.updatebounddata("data");
		// Rerender the main grid
		$grid.render();
		// Ensure the main grid is on the first page
		$grid.gotopage(0);
		// Wait until the grid is rendered
		/*$func.getLoadedStateAsync().then((val) => {
			// Call the render extension to resize the grid
			$grid.exRenderExpansion();
		});*/
		$func.updateBrands();
		// Update the filter by herb/spice list box
		$func.updateCommodity();
	}

	/**
	 * Performs a search on most columns in the dataset, and returns a subset of rows
	 * from the server that match the search term.
	 * @param  {any} searchTerm		The term to search for
	 * @returns void
	 */
	public doSearch = function(searchTerm: any): void {
		// Initialize the control variables
		let $var = window.ocConVars, $func = window.ocControl;
		// Check to see if any sub-categories have been selected
		if($var.source.data.subbrands[0] !== "all") {
			// if so, unselect them
			$var.source.data.subbrands=["all"];
		}

		if(searchTerm !== null) {
			// If there's actually a search term, check if there's a current search chip and remove it
			if($var.chipSet.chipExists("search", true)) {
				// Pass the third parameter as false to avoid doing a null search (which clears the search parameters)
				// fixing a bug that would cause the null search to return AFTER the second search results returned.
				$var.chipSet.removeChip("search",true,false);
			}
			// Add the new chip with our new search term
			$var.chipSet.addChip({title: searchTerm, chipType: "search"});
		} else {
			// If the search is null, it's essentially clearing the search
			if($var.chipSet.chipExists("search", true)) {
				// Check if a chip exists for search and remove it
				$var.chipSet.removeChip("search",true);
			}
		}

		// Assign a local variable to hold a reference to the global grid variable
		let $grid = $var.mainGrid;
		// Set the main grid's search term to the incoming search term
		$var.source.data.searchterm = searchTerm;
		// Update the grid's data
		$grid.updatebounddata("data");
		// Ensure the grid is on the first page
		$grid.gotopage(0);
		// Update the sub-category list box
		$func.updateSubBrand();
		// Update the herb/spice list box
		$func.updateCommodity();
	}

	/**
	 * Triggers the showAllDetails animations
	 * @param  {JQuery<HTMLElement>} btn	The button element passed from the click event
	 * @see EventHandlers.gridRendered
	 */
	public showAllDetails(btn: JQuery<HTMLElement>) {
		// Holds the main typescript variables for our control scripts.
		let $var = window.ocConVars, $func = window.ocControl, $event = window.ocEvents;

		$func.setProductSubTitleStart();

		// Initialize our function variables.
		let $btn = btn,
			rowindex = $btn.data("rowindex"),			// data-rowindex value; this will allow us to get the data for the row.
			$basicRow = $btn.parent().parent().siblings(".itemWrap").children(".basicRow");		// Reference to the basic info row

		// Change our button text to "Show Basic Info".
		$btn.html(`Show Basic Info <i class="fas fa-caret-up"></i>`);
		// Change the button data-status to "shown".
		$btn.data("status", "shown");

		// Initialize the rowData variable with data from the grid, and the $nF variable with our DisplayFunctions class.
		let rowData:rowData = $var.mainGrid.getrowdatabyid(rowindex), $nF: DisplayFunctions = new DisplayFunctions;
		//console.log("rowData: ", rowData);

		// Check if we're viewing by bulk or by consumer
		if($var.isBulk) {
			// If we're viewing bulk items, it means that the main rowData doesn't contain the entirety of the information,
			// but instead just grouping information. The individual product information is in a sub array-like object called
			// Products. The specific sub-product that was requested can be retrieved from the data-productindex property on the
			// clicked show all details button. When created in bulk mode, this property is assigned for exactly this reason.
			//rowData = rowData.Products[$btn.data("productindex")];
		}

		// Initialize variables for our UPC Code, Kosher status, and Stock Item status, and fill them from our
		// DisplayFunctions class.
		let hUpc: string = $nF.getUpc(rowData.UpcCode),
			hKosher: string = $nF.getKosher(rowData.Kosher),
			hStock: string = $nF.getStock(rowData.StockItem),
			hType: string = "";		// The hType variable is meant specifically for bulk listings, and is thus initialized as empty.
		/*if($var.isBulk) {
			// If displaying in bulk mode, we get the type from the rowData.BrandName property
			const regex = /\(Consumer\)|\(Industrial\)/gm;
			hType = rowData.BrandName
			if(rowData.BrandName.match(regex))
			{
				hType = hType.split("(")[1];
				hType = hType.split(")")[0];
			}
		}*/

		// Initialize variables for our column titles and fill them with either a blank string if the product doesn't have
		// a value for that spot, or with the correct column header if it does.
		let UpcTitle: string = hUpc === null ||  hUpc === "" ? "" : "UPC",
			KosherTitle: string = hKosher === null ||  hKosher === "" ? "" : "Kosher?",
			StockTitle: string = hStock === null ||  hStock === "" ? "" : "Stock Item?",
			iTypeType: string = hType === null || hType === "" ? "" : "Type";


		// Initialize the subheadRow variable containing our subheader HTML object that will be injected into the page. This
		// uses the title strings created above.
		let subheadRow: string = `<div class="headRow subHead">`;
			subheadRow += `<div></div>`;
			subheadRow += `<div>${UpcTitle}</div>`;
			subheadRow += `<div>${iTypeType}</div>`;
			subheadRow += `<div>${KosherTitle}</div>`;
			subheadRow += `<div>${StockTitle}</div>`;
		subheadRow += `</div>`;

		// Initialize the detRow variable containing our details HTML object that will be injected into the page. This
		// uses the results from the DisplayFunctions class declared above.
		let detRow: string = `<div class="itemRow detRow">`;
			detRow += `<div></div>`;
			detRow += `<div>${hUpc}</div>`;
			detRow += `<div>${hType}</div>`;
			detRow += `<div>${hKosher}</div>`;
			detRow += `<div>${hStock}</div>`;
		detRow += `</div>`;

		// Inject the details row into the page after the basic details row, and assign it's results
		// to a freshly initialized $detRow variable.
		let $detRow = $basicRow.after(detRow);
		// Inject the subheader row into the page after the details row. This seems counter intuitive,
		// but injecting the subheader before the details row, would make it appear after the details
		// row instead of before it. This has something to do with DOM traversal, and this was the easiest
		// solution to the issue.
		$detRow.after(subheadRow);

		// Call the new extension written for the jqWidgets grid, which can be found in /scripts/jqxgrid.refreshrows.extension.js.
		// Note that it was created using un-minimized code from the original jqxGrid library, and is essentially unreadable in it's
		// current form. At some point - especially if we continue to use the library in this and in future projects - a Developer License
		// purchased would be an extremely wise investment and is roughly $400 USD, which gives us full access to the source code.
		$var.mainGrid.exRenderExpansion();
	}
	
	/**
	 * Triggers the showBasicInfo animations
	 * @param  {JQuery<HTMLElement>} btn	The button element passed from the click event
	 */
	public hideAllDetails(btn: JQuery<HTMLElement>) {
		// Holds the main typescript variables for our control scripts.
		let $var = window.ocConVars, $func = window.ocControl, $event = window.ocEvents;
		// Initialize our function variables.
		let $btn = btn,
			$subRow = $btn.parent().parent().siblings(".itemWrap").children(".subHead"),		// Reference to the sub-header title row
			$detRow = $btn.parent().parent().siblings(".itemWrap").children(".detRow");		// Reference to the more details row.

		//$basicRow = $btn.parent().parent().siblings(".itemWrap").children(".basicRow");		// Reference to the basic info row

		// Assign our button text.
		$btn.html(`Show All Details <i class="fas fa-caret-down"></i>`);
		// Change the data parameter to show that the more information is hidden.
		$btn.data("status", "hidden");
		// Remove the sub-header row completely from the DOM.
		$subRow.remove();
		// Remove the details row completely from the DOM.
		$detRow.remove();
		// Call the new extension written for the jqWidgets grid, which can be found in /scripts/jqxgrid.refreshrows.extension.js.
		// See the note in showAllDetails for more on the extension.
		$var.mainGrid.exRenderExpansion();
	}

	/** Private property for the genUUID method */
	private idCounter: number = 0;
	/**
	 * Generates a unique integer ID, unique within the entire client session. Useful for
	 * temporary DOM ids.
	 * @param  {number|string} prefix
	 * @returns number
	 */
	public genUUID(prefix: number|string): number|string {
		let id = ++this.idCounter;// + '';
		let idMulti = Math.floor(Math.random() * 298090);
		id = id * idMulti;
		return prefix ? prefix + id.toString() : id;
	}

	/**
	 * Gets the element's left offset from it's parent.
	 * @param  {any} element
	 * @returns number
	 */
	public getElementLeft(element:any): number {
		let x: number = 0;
		// Set x to the element's offsetLeft;
		x = parseInt(element.offsetLeft);
		// Set the element to it's offsetParent
		element = element.offsetParent;

		// Use while loop to check if element is null.
		// If it's not, then add the current element's offsetLeft to x,
		// it's offsetTop to y, and set the element to its offsetParent.
		while(element != null) {
			x = x + parseInt(element.offsetLeft);
			element = element.offsetParent;
		}
		return x;
	}

	/**
	 * Gets the element's top offset from it's parent.
	 * @param  {any} element
	 * @returns number
	 */
	public getElementTop(element:any): number {
		let y: number = 0;
		// Set x to the element's offsetLeft;
		y = parseInt(element.offsetTop);
		// Set the element to it's offsetParent
		element = element.offsetParent;

		// Use while loop to check if element is null.
		// If it's not, then add the current element's offsetLeft to x,
		// it's offsetTop to y, and set the element to its offsetParent.
		while(element != null) {
			y = y + parseInt(element.offsetTop);
			element = element.offsetParent;
		}
		return y;
	}

	/**
	 * Refreshes the Filter by Brand, Filter by Brand-Category, and Filter by Herb/Spice list
	 * boxes in the filters bar. This is to fix a bug on mobile views, in which modification of
	 * the DOM in any way, would trigger the jqWidgets automatic resize feature based on parent
	 * elements, which in the case of the filter bar, would be 0px wide, visibility: hidden, and
	 * display: none. This resulted in the interior of the lists being set to 0px wide, thus hiding
	 * the list items. Called from a timer in @see EventHandlers.navOpen this refreshes the lists
	 * after the filters menu is close to or fully open, and reselects the items in each one that
	 * were previously checked.
	 * @see EventHandlers.navOpen
	 * @returns void
	 */
	public refreshLists():void {
		// Holds the main typescript variables for our control scripts.
		let $var = window.ocConVars, $func = window.ocControl, $event = window.ocEvents;

		let bChecks = $var.brandBox.getCheckedItems();
		let sbChecks = $var.subBrandBox.getCheckedItems();
		let hChecks = $var.herbBox.getCheckedItems();
		$var.brandBox.refresh();
		$var.subBrandBox.refresh();
		$var.herbBox.refresh();
		bChecks.forEach(element => {
			if(element.checked && !element.isGroup) {
				let elVal = $var.brandBox.getItemByValue(element.value);
				$var.brandBox.checkItem(elVal);
			}
		});
		sbChecks.forEach(element => {
			if(element.checked && !element.isGroup) {
				let elVal = $var.subBrandBox.getItemByValue(element.value);
				$var.subBrandBox.checkItem(elVal);
			}
		});
		hChecks.forEach(element => {
			if(element.checked && !element.isGroup) {
				let elVal = $var.herbBox.getItemByValue(element.value);
				$var.herbBox.checkItem(elVal);
			}
		});
	}

	/**
	 * Checks to see if the main product grid has finished loading its data, and then checks
	 * for incoming GET arguments.
	 * @param  {number} interval	The length of time before the setInterval fires.
	 * @returns void
	 */
	public getLocationTimeout(interval: number, callback?:() => void): void {
		// Intialize the control variables.
		let $vars = window.ocConVars, $func = window.ocControl, $event = window.ocEvents, that = this;
		// Create a new setInterval and assign it to a new local variable.
		let timeOut = setInterval(() => {
			// Check to see if the main grid's isBindingComplete is assigned.
			if($vars.mainGrid.isBindingCompleted !== undefined && $vars.mainGrid.isBindingCompleted)
			{
				// If so, clear the interval
				clearInterval(timeOut);
				if(callback && typeof callback === "function") {
					callback();
				}
			}
		}, interval);
	}

	/**
	 * Retrieves incoming GET arguments from the URL, and checks against a subset of
	 * possible commands, enabling certain filters if conditions are met.
	 * @returns void
	 */
	public getLocationArguments(): void {
		// Intialize the control variables.
		let $vars = window.ocConVars, $func = window.ocControl, $event = window.ocEvents;
		// Initialize the local variables
		let queryString = window.location.search,					// The entire GET query string, including the ?
			queryArr = queryString.replace("?","").split("&"),		// The modified GET query string as an array, removing the ? and splitting by the ampersand
			params: LooseObject = {};								// An object array of a custom loosely defined object type
		
		// Enter a for loop to iterate through the retrieved parameters
		for(var q = 0, qLength = queryArr.length; q < qLength; q++){
			// Split the parameter into its key and value pair, on the equal sign
			let qArr = queryArr[q].split("=");
			// Assign the newly split parameter to the params object array, with the key being the parameter name, and the value being it's value.
			params[qArr[0]] = qArr[1];
		}

		// Check to see if the params object has both line properties and industrial properties.
		if(params.hasOwnProperty("line") && params.hasOwnProperty("cat") && !params.hasOwnProperty("industrial")) {
			// Otherwise if it only has the line property, the user has requested a specific line's
			// products from the product listing table.
			// Initialize our local variables
			let tIn = decodeURIComponent(params.line),		// The incoming line parameter, decoded from the GET query
				line = $vars.brandBox.getItemByValue(tIn);	// The brand line in the Filter by Brand list box

			// Check to ensure that the brand line is not already checked in the Filter by Brand list box
			if(!line.checked) {
				// If it's not, check the item which will also update the grid.
				$vars.brandBox.checkItem(line);
			}
			$vars.mainGrid.isBindingCompleted;
			$func.getLocationTimeout(1000,() => {
				let tCat = decodeURIComponent(params.cat),
					catLine = $vars.subBrandBox.getItemByValue(tCat);

				if(!catLine.checked){
					$vars.subBrandBox.checkItem(catLine);
				}
			});
		}
		else if(params.hasOwnProperty("line") && params.hasOwnProperty("industrial")) {
			// If so, switch on the show bulk button.
			$("#ShowBulk").prop("checked", true);
			// Call the ControlFunctions.showBulk method to update the grid.
			$func.showBulk("ShowBulk");

			$func.getLocationTimeout(1000,() => {
				let tIn = decodeURIComponent(params.line),
					line = $vars.brandBox.getItemByValue(tIn);

				if(!line.checked){
					$vars.brandBox.checkItem(line);
				}
			});
			
		} else if(params.hasOwnProperty("line") && !params.hasOwnProperty("industrial") && !params.hasOwnProperty("cat")) {
			// Otherwise if it only has the line property, the user has requested a specific line's
			// products from the product listing table.
			// Initialize our local variables
			let tIn = decodeURIComponent(params.line),		// The incoming line parameter, decoded from the GET query
				line = $vars.brandBox.getItemByValue(tIn);	// The brand line in the Filter by Brand list box
			// Check to ensure that the brand line is not already checked in the Filter by Brand list box
			if(!line.checked) {
				// If it's not, check the item which will also update the grid.
				$vars.brandBox.checkItem(line);
			}
		}
		
	}

	/**
	 * Shows the hover/smaller popup version of the product image on certain mouseover events,
	 * click events, or larger screens.
	 * @param  {any} iTarget
	 * @returns string
	 */
	public showProductImageMouseover(iTarget: any): string {
		// Intialize the control variables
		let $var = window.ocConVars, $events = window.ocEvents, $func = window.ocControl;
		// Initialize the $img variable to be the jQuery reference to the
		// event target, as well as an eImg variable to the HTML element itself.
		let $img = $(iTarget), eImg = iTarget;

		// Create a unique UUID number for our generated elemented.
		let imgId: string|number = $func.genUUID(90010835);
		$var.curPopupId = typeof imgId === "number" ? imgId.toString() : imgId;
		// Create our popup HTML element with image. The height will be set to 300 pixels, while the
		// width will be dynamic.
		let popUp = `<div id="div_${imgId}" style="vertical-align: middle; position: absolute; border: 1px solid #999; background #fff; filter: Alpha(Opacity=100); visibility: visible; height: auto; width: auto; z-index: 999999950; overflow: hidden; text-align: center;">`;
			popUp += `<img id="img_${imgId}" height="300" src="${$img.data("picture")}">`
		popUp += `</div>`;
		// Add the new popup element to the end of the body.
		$(document.body).append(popUp);
		// Retrieve the popup outer div element by its uniquely generated UUID
		let popElement = document.getElementById(`div_${imgId}`);
		// Retrieve the inner popup image element by its uniquely generated UUID
		let htmlImg = document.getElementById(`img_${imgId}`);

		// Intialize the positioning variables:
		// 		elLeft:		left edge of the original product thumbnail
		//		elTop:		top edge of the original product thumbnail
		//		popWidth:	Width of the DIV element
		//		popHeight:	Height of the DIV element
		//		winWidth:	Width of the browser window
		//		winHeight:	Height of the browser window
		let elLeft = $func.getElementLeft(iTarget) + 1,
			elTop = $func.getElementTop(iTarget) + 1,
			popWidth = $(popElement).outerWidth(),
			popHeight = $(popElement).outerHeight(),
			winWidth = $(window).width(),
			winHeight = $(window).height(),
			winTop = $(window).scrollTop();

		// Initialize our final position variables, setting our default left edge to be equal to the left
		// edge of the product thumbnail plus one pixel, and default top edge to be equal to the top edge
		// of the thumbnail, minus the DIV's height divided by 3.
		let fLeft = elLeft - (popWidth / 1.5), fTop = elTop - (popHeight / 3);

		// Check to see if the right edge of the DIV is going to be past the right edge of the window.
		if(elLeft + popWidth > winWidth) {
			// If so, move it so that it's completely on screen; left edge of the thumbnail, minus
			// the the DIV's width divided by 1.5.
			fLeft = elLeft - (fLeft / 1.5);
		}

		// Using jQuery. set our left and top properties to to the fLeft and fTop values defined above,
		// and ensure that there is a "px" value tacked on the end. Unit values are important for any CSS
		// positioning, especially when done via Javascript.
		$(popElement).css({
			left: fLeft + "px",
			top: fTop + "px"
		});
		
		// Javascript seems unable to properly get the top/bottom element position before the popElement has
		// been positioned via the CSS property. Functionality, this means it needs to be written to the page,
		// displayed, checked, and then modified if the bounding is out of range.
		let isOut = $func.isOutofViewport(popElement);
		// Check if the bottom of the popup element is out of the bottom of the viewport.
		if(isOut.bottom) {
			// Initialize the variables to hold a reference to viewport bottom, a divisor to move the popup
			// element by, and the final top height to set the element to.
			let winBottom = winTop + winHeight, divisor = popHeight * 1.05, nTop = winBottom - divisor;
			// Set the element's top CSS property
			$(popElement).css({
				top: nTop + "px"
			})
		}
		// Check if the top of the element is out of the top of the viewport. See the ControlFunctions.isOutofViewport()
		// method to see the definition of top of viewport in our case, as it's not actually 0.
		if(isOut.top) {
			// Set the element's top CSS property to the top of the window, minus the navigation menu bar,
			// plus a little bit extra so there's a slight bit of clearance.
			$(popElement).css({
				top: (winTop + 75) + "px"
			});
		}
		// Check if the right edge of the element is out of the right edge of the viewport.
		if(isOut.right) {
			// Initialize variables to hold a reference to the viewport right, a divisor to move the popup
			// elementby, and the final left edge to set the element to.
			let nWidth = $(window).scrollLeft() + winWidth, divisor = popWidth * 1.05, nLeft = nWidth - divisor;
			// Set 
			$(popElement).css({
				left: nLeft + "px"
			});
		}

		return $var.curPopupId;
	}

	/**
	 * Initializes all event handlers needed for removing a mouse-over pop-up
	 * image from the page.
	 * @returns void
	 */
	public hideProductImageMouseover(): void {
		// Intialize the control variables
		let $var = window.ocConVars, $events = window.ocEvents, $func = window.ocControl;
		// Initialize popup number variables;
		let imgId = $var.curPopupId, $popElement = $(`#div_${imgId}`), $htmlImg = $(`#img_${imgId}`);

		$popElement.on("mouseout", (e) => {
			$var.curPopupId = null;
			$popElement.off("mouseout");
			$htmlImg.off("mouseout");
			$popElement.remove();
			$(document).off("click.ocImageSpace");
			$(document).off("closeImage.ocImageSpace");
		});
		$htmlImg.on("mouseout", (e) => {
			$var.curPopupId = null;
			$popElement.off("mouseout");
			$htmlImg.off("mouseout");
			$popElement.remove();
			$(document).off("click.ocImageSpace");
			$(document).off("closeImage.ocImageSpace");
		});
		setTimeout(() => {
			$(document).on({
				"closeImage.ocImageSpace": (ei) => {
					if($popElement.length > 0 && $var.curPopupId != null) {
						$var.curPopupId = null;
						$popElement.off("mouseout");
						$htmlImg.off("mouseout");
						$popElement.remove();
						$(document).off("click.ocImageSpace");
						$(document).off("closeImage.ocImageSpace");
					}
					$(document).off("click.ocImageSpace");
					$(document).off("closeImage.ocImageSpace");
				},
				"click.ocImageSpace": (ec) => {
					$(document).trigger("closeImage.ocImageSpace");
				},
			});
		}, 150);

	}

	/**
	 * Determines if any or all edges of a given HTML element are outside the viewable
	 * area of the browser window.
	 * @param  {any} elem		The HTML element to check
	 * @returns BoundingRect	An objecting containing the boolean values for each side, any side, and all sides.
	 */
	public isOutofViewport(elem: any): BoundingRect {
		// Get the bounding rectangle vs the client area.
		let bounding = elem.getBoundingClientRect();
		// Initialize the output variable with all its properties initially set to false.
		let out: BoundingRect = {
			top: false,
			left: false,
			bottom: false,
			right: false,
			any: false,
			all: false
		};
		// Initialize references for the navigation bar and the title bar.
		let $navBar = $("#mainNav"), $titleBar = $("#gridTitle");
		// Initialize variables for the navigation bar height, title bar height, and total top distance.
		let navTop = $navBar.outerHeight(true), titleTop = $titleBar.outerHeight(), totalTop = 0;
		// Set the total top distance variable, adding 5px to whatever the calculated result is.
		totalTop = navTop + titleTop + 5;

		// Check if the top edge is outside of the top edge of the viewable content, including the current
		// height of the navigation bar and title bar.
		out.top = bounding.top < totalTop;
		// Check if the left edge is outside the left edge of the window.
		out.left = bounding.left < 0;
		// Check if the bottom edge is outside the bottom edge of the window.
		out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
		// Check if the right edge is outside the right edge of the window.
		out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
		// Check if any of the edges are outside the edges of the window
		out.any = out.top || out.left || out.bottom || out.right;
		// Check if all the edges are outside the edges of the window.
		out.all = out.top && out.left && out.bottom && out.right;

		// Return the output variable to the calling method.
		return out;
	}

	/**
	 * Opens a full-screen version of the image popup
	 * @param  {string} img			The url and/or relative path to the image file
	 * @param  {number} maxWidth?	(optional) The maximum width the image can stretch to
	 * @param  {number} maxHeight?	(optional) The maximum height the image can stretch to
	 * @returns void
	 */
	openImage(img: string, maxWidth?: number, maxHeight?: number): void {
		// Initialize control variables
		let $event = window.ocEvents;
		// Initialize local variables
		let oc_overlay = $("#oc_imgpop_overlay"),	// The reference to the overlay
			oc_img = $("#oc_imgpop_dialog"),		// The reference to the image popup wrapper
			iEx = oc_img.find("img");				// The holder to see if the image has already been added

		// Initialize an empty string to hold the inline max width/height style.
		let imgStyle:string = "";
		if(typeof maxWidth !== "undefined" && maxWidth !== null) {
			// If there's an incoming max-width value, add it to the style list.
			imgStyle += `max-width: ${maxWidth}px;`;
		}
		if(typeof maxHeight !== "undefined" && maxHeight !== null) {
			// If there's an incoming max-height style, add it to the style list.
			imgStyle += `max-height: ${maxHeight}px;`;
		}
		// Wrap the CSS tags in the style attribute for the HTML element.
		imgStyle = imgStyle !== "" && imgStyle !== null ? `style="${imgStyle}"` : "";
		
		// Create the image to be displayed, using the inline style tag created above.
		let tImg = `<img src="${img}" ${imgStyle} />`;
		
		// Check to see if the image has already been added. This is done by using the
		// jQuery $.find command on the IMG tag which will return an array of elements
		// found within the container. If the length of the array is 0, it means that
		// the image hasn't been added yet, and it's safe to add the image to the wrapper.
		// If the length is greater than 0, the image exists, and the image shouldn't be
		// added, as it means duplicates will appear.
		if(iEx.length === 0) {
			// No image is added. Append the image to the popup wrapper
			oc_img.append(tImg);
		}

		// Quickly fade the overlay into the page
		oc_overlay.fadeIn("fast", () => {
			// Slowly fade the popup wrapper into the page overtop the overlay, once the overlay
			// is finished fading in. This is ensured to take place in sequence by placing the
			// secondary code within the anonymous callback
			oc_img.fadeIn("slow", () => {
				// Ensure the body has an overflow:hidden class applied to it so that it can't scroll
				// while the image is displayed. This only partially holds true on mobile devices,
				// as at least Chrome will still allow you to scoll enough to move the address bar
				// and such out of the view. As with the fadein code, this takes place in sequence
				// by using an anonymous callback method, but could just as easily be broken out into
				// external callbacks.
				$(`body`).addClass("bodyNoScroll");
			});
		});
		
		// Find the close-image "X" icon on the popup wrapper, and assign it a new event handler pointing
		// to the EventHandlers.closeImage handler
		oc_img.find("i").on("click", $event.closeImage);
	}

	/**
	 * Moves the main product column titlebar to the proper spot on the page, including an
	 * option offset to move it below the main menu by an optional offset
	 * @param  {number} offset?		The amount to offset from the menu by.
	 * @returns void
	 */
	public setProductSubTitleStart(offset?: number): void {
		// Initialize the control variables
		let $var = window.ocConVars, $events = window.ocEvents, $func = window.ocControl;
		// Initialize the local variables
		let $titleBar = $("#testtitle"),
			$chipbar = $("#chip_wrapper"),
			$filterbar = $("#filter-panel"),
			$mainNav = $("#mainNav"),
			filterWidth = $filterbar.outerWidth(),
			chipHeight = $chipbar.outerHeight(),
			titleWidth = $titleBar.outerWidth(),
			titleHeight = $titleBar.outerHeight(),
			navHeight = $mainNav.outerHeight();

			offset = typeof offset !== "undefined" && offset !== null ? offset : 0;

		// Set grid top
		let $spacer = $("#gridspacer");
		let $fspacer = $("#filter_spacer");
		// Set margins of the grid spacer to move the grid and chips section down to be visible
		let fTop = (titleHeight + chipHeight) + offset;// + offset;
		let chipTop = titleHeight;
		let descHeight = 0;
		if(navHeight > 70) {
			let tHeight = navHeight - 70;
			fTop += tHeight;
			chipTop += tHeight;
			descHeight = tHeight;
		}

		// Initialize the height variables for use with the filter panel
		let titleTopPos: number = $titleBar.position().top;

		// Update filters panel position; needed on medium/large screens
		$filterbar.css({
			"top": titleTopPos + "px",
			"margin-top": descHeight + "px"
	 	});

		//console.log(`Setting filterPanel top to ${fiTop}px`);

		let fLeft = (filterWidth);
		$titleBar.css({
			"margin-top": descHeight + "px"
		});
		$spacer.css({
			"margin-top": fTop + "px",
		});
		$chipbar.css({
			"margin-top": chipTop + "px",
			"width": titleWidth + "px"
		});
		$fspacer.css({
			"margin-right": fLeft + "px"
		});

		$func.setProductTitleWidth();
	}

	/**
	 * Calculates the width of the product grid and sets the column title bar to be the
	 * same width. Additionally changes the text shown
	 * @returns void
	 */
	public setProductTitleWidth(): void {
		// Initialize control variables
		let $var = window.ocConVars, $events = window.ocEvents, $func = window.ocControl;
		let $titleBar = $("#testtitle"),
			$grid = $("#jqxgrid"),
			gridWidth = $grid.outerWidth(false),// - 10,
			winWidth = window.innerWidth,
			showPageText = "Show # Of Items Per Page",
			gotoPageText = "";

		if(winWidth < 768) {
			// Small screens
			showPageText = "Show";
			$("#showntext").hide();
			$("#gototext").text("Goto");
		} else if(winWidth >= 768 && winWidth < 992){
			// Medium screens
			showPageText = "Show Per Page";
			$("#showntext").hide();
			$("#gototext").text("Go to");
		} else if(winWidth >= 992) {
			// Large screens
			showPageText = "Show # Of Products Per Page";
			$("#showntext").show();
			$("#gototext").text("Go to page");
		}
		$("#showpagetext").text(showPageText);

		$titleBar.css({
			width: gridWidth + "px"
		});
	}

	/**
	 * DEPRECIATED Adds or removes the sub-header to the main product column titlebar and recalculates the
	 * proper heights, margins, paddings, and top positions of various DOM elements.
	 * @param  {number} offset?		(default: 0) The amount to offset the elements by
	 * @param  {boolean} remove?	(default: false) Whether to add or remove the subheader
	 * @returns void
	 */
	public updateProductTitle(offset?:number, remove?: boolean):void {
		// Initialize the control variables
		let $var = window.ocConVars, $events = window.ocEvents, $func = window.ocControl;

		// If the add parameter isn't passed in, assume that it's to add the title bar.
		remove = typeof(remove) === "undefined" || remove === null || remove === false ? false : true;

		// Initialize the local references
		let $navBar = $("#mainNav"),
			$titleBar = $("#gridTitle"),
			$filterPanel = $("#filter-panel"),
			$plines = $("#productlines"),
			$filterCol = $("#filterCollapse"),
			$clsBtn = $(".closebtn"),
			$btn = $("#showDetails");

		
		// Initialize the subheadRow variable containing our subheader HTML object that will be injected into the page. This
		// uses the title strings created above.
		let subheadRow: string = `<div class="headRow subHead" id="gridTitleSubRow">`;
			subheadRow += `<div></div>`;
			subheadRow += `<div>UPC</div>`;
			subheadRow += `<div></div>`;
			subheadRow += `<div>Kosher?</div>`;
			subheadRow += `<div>Stock Item?</div>`;
		subheadRow += `</div>`;
		if(!remove) {
			// Add the sub header to the page
			$titleBar.append(subheadRow);
		} else if(remove) {
			// Check to see if the subheader exists
			let $sHead = $titleBar.find("#gridTitleSubRow");
			if($sHead.length > 0) {
				// The subheader exists on the page; remove it from the DOM
				$sHead.remove();
			}
		}


		// Initialize the local variables that get the heights and positions. This needs to be done
		// after the above creation code.
		let navPos = $navBar.position(),
			navHeight = $navBar.outerHeight(true),
			navTop = navPos.top,
			titleHeight = $titleBar.outerHeight();

		offset = typeof offset !== "undefined" && offset !== null ? offset : 0;
		
		// Initialize the height variables
		let fTop:number = (navTop + navHeight),
			fiTop:number = (fTop + titleHeight) + offset,
			pTop:number = (fTop + titleHeight) + offset,
			cTop: number = (offset * 2) + pTop;

		// Update filters panel position; needed on medium/large screens
		//$filterPanel.css({ "top": (fiTop + 10) + "px" });
		$filterPanel.css({ "top": fiTop + "px" });
		console.log(`Setting filterPanel top to ${fiTop}px`);
		// Update grid/chip area top and padding so it's not overlapped by the titlebar
		$plines.css({
			"margin-top": pTop + "px",
			"padding-top": "0"
		});
		$clsBtn.css({
			"top": cTop + "px"
		});

		if(!remove){
			// Add the custom class with the measured title bar area to the expanded filter
			// menu. Not the most ideal solution, but it works for the time being.
			$filterCol.addClass("fSideStatus");
		} else if(remove) {
			// Remove the custom status class as the sub-header is being removed.
			$filterCol.removeClass("fSideStatus");
		}
	}

	/**
	 * Adds or removes the sub-header to the main product column titlebar and recalculates the
	 * proper heights, margins, paddings, and top positions of various DOM elements.
	 * @param  {number} offset?		(default: 0) The amount to offset the elements by
	 * @param  {boolean} remove?	(default: false) Whether to add or remove the subheader
	 * @returns void
	 */
	public updateProductSubTitle(offset?:number, remove?: boolean):void {
		// Initialize the control variables
		let $var = window.ocConVars, $events = window.ocEvents, $func = window.ocControl;

		// If the add parameter isn't passed in, assume that it's to add the title bar.
		remove = typeof(remove) === "undefined" || remove === null || remove === false ? false : true;

		// Initialize the local references
		let $navBar = $("#mainNav"),
			$titleBar = $("#testtitle"),
			$chipbar = $("#chip_wrapper"),
			$plines = $("#gridspacer"),
			$clsBtn = $(".closebtn"),
			$btn = $("#showDetails"),
			$rowWrapper = $(".headRowWrap");

		
		// Initialize the subheadRow variable containing our subheader HTML object that will be injected into the page. This
		// uses the title strings created above.
		let subheadRow: string = `<div class="headRow subHead" id="gridTitleSubRow2">`;
			subheadRow += `<div></div>`;
			subheadRow += `<div>UPC</div>`;
			subheadRow += `<div></div>`;
			subheadRow += `<div>Kosher?</div>`;
			//subheadRow += `<div>Stock Item?</div>`;
			subheadRow += `<div></div>`;
		subheadRow += `</div>`;

		let $sHead = $titleBar.find("#gridTitleSubRow2");
		let sTitle = "";
		if(!remove) {
			sTitle = "Add ";
			if($sHead.length > 0) {
				$sHead.remove();
			}
			// Add the sub header to the page
			$rowWrapper.append(subheadRow);
		} else if(remove) {
			sTitle = "Remove "
			// Check to see if the subheader exists
			
			if($sHead.length > 0) {
				// The subheader exists on the page; remove it from the DOM
				$sHead.remove();
			}
		}


		// Initialize the local variables that get the heights and positions. This needs to be done
		// after the above creation code.
		let titleHeight = $titleBar.outerHeight(), chipHeight = $chipbar.outerHeight();

		offset = typeof offset !== "undefined" && offset !== null ? offset : 0;
		
		// Update grid/chip area top and padding so it's not overlapped by the titlebar
		let pTop = (titleHeight + chipHeight) + offset;
		$plines.css({
			"margin-top": pTop + "px"
		});
		$chipbar.css({
			"margin-top": titleHeight + "px"
		});

	}

	/**
	 * Updates a product's details with the extra information about a product. Currently only
	 * the UPC code, Kosher status, and Stock Item status. The columns remain empty if no value
	 * exists for them
	 * @param  {boolean} remove?
	 * @returns void
	 */
	public updateProductDetails(remove?:boolean):void {
		// Initialize the control variables
		let $var = window.ocConVars, $events = window.ocEvents, $func = window.ocControl, $grid = $var.mainGrid, $nF = new DisplayFunctions;

		let $btn = $("#showDetails"),
			$titleBar = $("#testtitle"),
			$isSub = $titleBar.find("#gridTitleSubRow2");

		// Get the rows currently displayed within the grid
		let rows = $grid.getdisplayrows();
		// Use the jQuery $.each method to loop through the displayed rows. More information on the $.each method can be
		// found in the jQuery API documentation here: https://api.jquery.com/jquery.each/
		$.each(rows, (rowid, row) => {
			if(!$var.isBulk) {
				// If the bulk products view is not selected..
				if(!row.hasOwnProperty("group")) {
					// If the current row is not a group display row
					// Initialize the variables to hold the extra information
					let hUpc: string = $nF.getUpc(row.UpcCode),			// Retrieve the UPC Code using DisplayFunctions.getUpc
						hKosher: string = $nF.getKosher(row.Kosher),	// Retrieve the Kosher status using DisplayFunctions.getKosher
						hStock: string = $nF.getStock(row.StockItem);	// Retrieve the Stock status using DisplayFunctions.getStock

					// Intialize the final HTML element that will be injected into the DOM.
					let detRow: string = `<div class="itemRow detRow">`;
						detRow += `<div></div>`;
						detRow += `<div></div>`;
						detRow += `<div>${hUpc}</div>`;
						detRow += `<div></div>`;
						detRow += `<div>${hKosher}</div>`;
						detRow += `<div>${hStock}</div>`;
					detRow += `</div>`;
					//console.log(detRow);

					// Find the row with the current product's identification number assigned to it by the
					// grid. This comes from the rowIndex parameter passed to the ControlFunctions.renderConsumer
					// and ControlFunctions.renderBulk methods that are called from ControlFunctions.renderProduct,
					// and are assigned during the individual product creation. Consumer or "All Items" have only
					// a row index associated with them as there's no grouping beyond the main category grouping.
					let $nRow = $(`[data-rowindex="${row.uid}"]`);
					if(!remove) {
						// If the remove flag isn't set, inject the details row into the DOM immediately following
						// the basic information row, so that it becomes the hierarchical sibling of the basic details
						// row.
						let $tRow = $nRow.siblings(".detRow");
						if($tRow.length > 0) {
							$tRow.remove();
						}
						
						$nRow.after(detRow);
					} else if(remove) {
						// If the remove flag is set, find the sibling details row
						let $rem = $nRow.siblings(".detRow");
						// Remove the sibling details row completely from the DOM.
						$rem.remove();
					}

				}
			} else if($var.isBulk) {
				// if the bulk products flag is set
				if(!row.hasOwnProperty("group")) {
					// If the current row is not a group display row

					// Find the row with the current product's identification number assigned to it by the
					// grid. This comes from the rowIndex parameter passed to the ControlFunctions.renderConsumer
					// and ControlFunctions.renderBulk methods that are called from ControlFunctions.renderProduct,
					// and are assigned during the individual product creation. 
					let $nRows = $(`[data-rowindex="${row.uid}"]`);
					// Since bulk item view may have one or more products associated with each item, we need to
					// loop through each product found, which will have an additional data-productindex attribute
					// assigned to it during its creation. More information on the $.each method can be found in
					// the jQuery API documentation here: https://api.jquery.com/jquery.each/
					$.each($nRows,(index, nRow) => {
						// Get the product index from the current product
						let curProd = $(nRow).data("productindex");
						let curBrand = $(nRow).data("productbrand");

						//console.log("show details - product brand: ", curBrand);
						//console.log("show details - product index: ", curProd);

						// Initialize a variable to hold the current row information from the Products sub-array,
						// and set it to null so it can error trapped later.
						let curRow = null;
						//console.log(row);
						if(row.hasOwnProperty("Products")){
							// Check if the current row in the grid's data has a sub-array named
							// "Products", and that its length is greater than 0. If so, set the
							// curRow variable initialized above to be the value of the Products
							// sub-array at the position indicated by the productindex attribute's
							// value.
							// This subroutine is no longer checking for the length of the Products
							// array, as the bulk items are now sub-grouped into separate brand
							// categories, and because of typecasting the Products array no longer
							// contains a length variable.
							curRow = row.Products[curBrand][curProd];
							//if(row.Products.length > 0) {}
						} else {
							// If either of those properties were false, there is a serious error.
							// Throw a new Javascript error.
							throw new Error("Unable to find correct product in bulk product group");
						}

						// Check to make sure the curRow variable is not null; this is the start of the
						// error trapping.
						if(curRow !== null) {
							// Initialize the extra data variables and assign their values
							let hUpc: string = $nF.getUpc(curRow.UpcCode),			// Retrieve the UPC code from DisplayFunctions.getUpc
								hKosher: string = $nF.getKosher(curRow.Kosher),		// Retrieve the Kosher status from DisplayFunctions.getKosher
								hStock: string = $nF.getStock(curRow.StockItem);	// Retrieve the Stock status from DisplayFunctions.getStock

							// Initialize the final HTML element that will be injected into the DOM.
							let detRow: string = `<div class="itemRow detRow">`;
								detRow += `<div></div>`;
								detRow += `<div>${hUpc}</div>`;
								detRow += `<div></div>`;
								detRow += `<div>${hKosher}</div>`;
								detRow += `<div>${hStock}</div>`;
							detRow += `</div>`;

							if(!remove) {
								// If the remove flag isn't set, inject the details row into the DOM immediately following
								// the basic information row, so that it becomes the hierarchical sibling of the basic details
								// row for this specific product.
								let $tRow = $(nRow).siblings(".detRow");
								if($tRow.length > 0){
									$tRow.remove();
								}
								$(nRow).after(detRow);
							} else if(remove) {
								// If the remove flag is set, find the sibling details row for this specific product.
								let $rem = $(nRow).siblings(".detRow");
								// Remove the sibling details row completely from the DOM.
								$rem.remove();
							}
						}
						//console.log(`Current row index is ${index} for row:\r\n\t`, nRow, `\r\n\twith product index: ${curProd}\r\n\tand has rowdata: `, curRow);
					});
				}
			}
		});
		// Call the render extension module for the grid to account for any sizing updates that need to take place.
		$grid.exRenderExpansion();
	}

	/**
	 * Asynchronous method that checks the currently displayed rows in the grid, and waits
	 * until the returned array has rows in it before it resolves the inherent promise.
	 * This allows the use of either the await modifier or the .then().catch().finally()
	 * syntax.
	 * @returns Promise<boolean>	Returns true when the grid has row data populated
	 */
	public async getLoadedStateAsync(): Promise<boolean> {
		// Initialize the control variables
		let $var = window.ocConVars, $grid = $var.mainGrid;
		// Initialize the promise return with the resolve anonymous inline arrow function
		let prom = new Promise<boolean>((resolve, reject) => {
			// Initialize the row variable and populate it with a call to the grid's getdisplayrows
			// method which returns an array of rows visible on the current page.
			let row = $grid.getdisplayrows();
			// Initialize a variable to act as an interval handler
			let tOut = null;
			// Check the rows array length
			if(row.length === 0) {
				// If the row array length is 0
				// Populate the tOut handler variable with an interval initialization that
				// repeats every 300 milliseconds.
				tOut = setInterval(() => {
					// On every iteration of the interval, run a new call to the grid's
					// getdisplayrows method.
					row = $grid.getdisplayrows();
					if(row.length > 0) {
						// If the row array length is greater than 0, clear the interval
						clearInterval(tOut);
						// Resolve the promise with a true value
						resolve(true);
					}
				},300);
			} else if( row.length > 0) {
				// If the initial check of the row array length is greater than 0,
				// immediately resolve the promise with a true value.
				resolve(true);
			}
		});
		return prom;
	}

	/**
	 * Updates the source types for the brand data list.
	 */
	public loadBrandDataTypes(): void {
		let $var = window.ocConVars;
		// Updated the brand search parameters with the ones assigned to the main product grid.
		// Note that brands and designations should always be all, regardless of what other
		// filters are selected.
		$var.brandSource.data.searchterm = $var.source.data.searchterm;
		$var.brandSource.data.brands = ["all"];
		$var.brandSource.data.designation = ["all"];
		$var.brandSource.data.bulk = $var.source.data.bulk;
		$var.brandSource.data.kosher = $var.source.data.kosher;
	}

	/**
	 * Updates the source types for the Brand-Categories list.
	 */
	public loadSubDataTypes():void {
		let $var = window.ocConVars;
		// Update the subcategory search parameters with the ones assigned to the main product grid.
		$var.subcatSource.data.searchterm = $var.source.data.searchterm;
		$var.subcatSource.data.brands = $var.source.data.brands;
		$var.subcatSource.data.designations = $var.source.data.designations;
		$var.subcatSource.data.bulk = $var.source.data.bulk;
		$var.subcatSource.data.kosher = $var.source.data.kosher;
	}

	/**
	 * Updates the source types for the herbs list.
	 */
	public loadHerbDataTypes(): void {
		let $var = window.ocConVars;
		// Update the commodity search parameters with the ones assigned to the main product grid.
		$var.commSource.data.searchterm = $var.source.data.searchterm;
		$var.commSource.data.brands = $var.source.data.brands;
		$var.commSource.data.subbrands = $var.source.data.subbrands;
		$var.commSource.data.designations = $var.source.data.designations
		//$var.commSource.data.industrial = $var.source.data.industrial;
		$var.commSource.data.industrial = $var.source.data.bulk;
		$var.commSource.data.kosher = $var.source.data.kosher;
		$var.commSource.data.bulk = $var.source.data.bulk;
		$var.commSource.data.stock = $var.source.data.stock;
	}

	public escapeHtml(html: string): string {
		return html
			.replace(/&/g, "&amp;")
			.replace(/</g, "&lt;")
			.replace(/>/g, "&gt;")
			.replace(/"/g, "&quot;")
			.replace(/'/g, "&#039");
	}

	public runUpdateDetails() {
		let $var = window.ocConVars, $func = window.ocControl;
		$func.setProductSubTitleStart();
		// let $btn = $("#showDetails"),
		// 	$titleBar = $("#gridTitle"),
		// 	$isSub = $titleBar.find("#gridTitleSubRow");
		let $btn = $("#showDetails"),
			$titleBar = $("#testtitle"),
			$isSub = $titleBar.find("#gridTitleSubRow2");

		// Check to see if the details shown is false, and the subTitleRow was not found
		if(!$var.detailsShown && $isSub.length === 0) {
			// Set the details shown to true
			$var.detailsShown = true;
			// Ensure that the detailsShown variable on the window is true. This is needed
			// for the jqxGrid extension which updates things.
			window.detailsShown = true;
			// Set the button's details
			//<button class="btn btn-sm btn-outline-orgcon" id="showDetails"></button>
			$btn.html(`Show Basic Info <i class="fas fa-caret-up"></i>`);
			// Add the active classing, showing it as a lighter green.
			// $btn.addClass("btn-orgcon active").removeClass("btn-outline-orgcon");
			$btn.addClass("active");
			// Update the sub-title bar
			$func.updateProductSubTitle(0, false);
			// Update the product details
			$func.updateProductDetails(false);
		}
		else if($var.detailsShown && $isSub.length > 0) {
			// The detailsShown is true, and the subTitleRow was found.
			// Set the details shown to false.
			$var.detailsShown = false;
			// Ensure that the detailsShown variable on the window is false. This is needed for the
			// jqxGrid extension which updates things.
			window.detailsShown = false;
			// Set the button's details
			$btn.html(`Show All Details <i class="fas fa-caret-down"></i>`);
			// Remove the active class
			// $btn.removeClass("btn-orgcon active").addClass("btn-outline-orgcon");
			$btn.removeClass("active");
			// Update the sub-title bar
			$func.updateProductSubTitle(0,true);
			// Update the product details
			$func.updateProductDetails(true);
		}
		else {
			// Somehow reached an unsync'd state. This should, in theory, never happen.
			// Set the details shown to false.
			$var.detailsShown = false;
			// Ensure that the detailsShown variable on the window is false. This is needed for the
			// jqxGrid extension which updates things.
			window.detailsShown = false;
			// Set the button's details
			$btn.html(`Show All Details <i class="fas fa-caret-down"></i>`);
			// Remove the active class
			// $btn.removeClass("btn-orgcon active").addClass("btn-outline-orgcon");
			$btn.removeClass("active");
			// Update the sub-title bar
			$func.updateProductSubTitle(0,true);
			// Update the product details
			$func.updateProductDetails(true);
		}

		$func.setProductSubTitleStart();
	}

	
}

/**
 * Holds the methods responsible for getting the display values for a product's details.
 */
export class DisplayFunctions {
	/**
	 * Returns the designation of the product in full text format
	 * @param  {string} value	The value from the database.
	 * @returns string			A plain string to be displayed in the product description.
	 */
	public getTextDesignation(value: string): string {
		if (value === "ORG")
		{
			return `Organic`;
		}
		else if(value === "ORG-90")
		{
			return `90% Organic Ingredients`;
		}
		else if (value === "PQ")
		{
			return `Pure Quality`;
		}
		else if (value === "WC")
		{
			return `Wildcrafted`;
		}
		else if (value === "NA")
		{
			return "";
		}
		else
		{
			return "";
		}
	}
	
	/**
	 * Returns the pack size of a product. Ensures that the incoming value is not null, empty, or undefined.
	 * @param  {string} value	The value from the database.
	 * @returns string			A plain string to be displayed in the product description
	 */
	public getSize(value: string): string {
		if(value !== null && value !== "" && typeof(value) !== "undefined")
		{
			return `${value}`;
		}
		else
		{
			return "";
		}
	}
	
	/**
	 * Returns the availability of a product. Ensures that the incoming value is not undefined.
	 * @param  {string} value	The value from the database.
	 * @returns string			An HTML element wrapped in a string, that will be inserted into the product description as an icon.
	 */
	public getAvailable(value: string): string {
		if (typeof(value) !== "undefined" && value.toLowerCase() === "available")
		{
			return `<i title="Available" class="fas fa-check-circle ml-2 mr-1" style="font-size: 1rem; font-weight: bold; color: #5B955A"></i>`;
		}
		else if (typeof(value) !== "undefined" && value.toLowerCase() === "temp. out of stock")
		{
			return `<i title="Temp. Out of Stock - Order now to reserve yours" class="oci-outofstock ml-2 mr-1" style="font-size: 0.8rem; font-weight: bold; color: #ffc107; -webkit-text-stroke: 1px #000;"></i>`;
		}
		else if (typeof(value) !== "undefined" && value.toLowerCase() === "inquire")
		{
			return `<i title="High volume item - inquire for custom quote and timeline" class="fas fa-question-circle ml-2 mr-1" style="font-size: 1rem; color: #17a2b8; font-weight: bold;"></i>`;
		}
		else
		{
			return "";
		}
	}
	
	/**
	 * Gets the picture for a product. Ensures that both thumbnail and hires are not empty, null, 0 or undefined.
	 * @param  {string} picThumb	The thumbnail value from the database.
	 * @param  {string} picMain		The hires value from the database.
	 * @returns string				An HTML element wrapped in a string, that will be inserted into the product description.
	 */
	public getPic(picThumb: string, picMain: string): string {
		let tValue = null;
		if(picThumb !== null && picThumb !== "" && picThumb !== "0" && typeof(picThumb) !== "undefined")
		{
			tValue = picThumb;
		}
		if(picMain !== null && picMain !== "" && picMain !== "0" && typeof(picMain) !== "undefined")
		{
			tValue = picMain;
		}

		if(tValue !== null && tValue !== "" && typeof(tValue) !== "undefined")
		{
			let retString = `<div class="picCell">`;
				retString += `<img height="65" data-picture="${tValue}" src="${tValue}" />`;
			retString += `</div>`;
			return retString;
		}
		else
		{
			return ``;
		}
	}
	
	/**
	 * Gets the marketing comments for a product. Ensures that the value is not null, empty or undefined.
	 * @param  {string} value	The value from the database.
	 * @returns string			A plain string that will displayed in the product description.
	 */
	public getComments(value: string): string {
		if(value !== null && value !== "" && typeof(value) !== "undefined")
		{
			return `${value}`;
		}
		else
		{
			return "";
		}
	}
	
	/**
	 * Gets the UPC Code for a p4roduct. Ensures the value is not null, empty or undefined.
	 * @param  {string} value	The value from the database.
	 * @returns string			A plain string that will be dislayed in the product description.
	 */
	public getUpc(value: string): string {
		if(value !== null && value !== "" && typeof(value) !== "undefined")
		{
			let retCode = "";
			retCode = value.substring(0, 1);
			retCode += `<span class="spacing"></span>`;
			retCode += value.substring(1, 6);
			retCode += `<span class="spacing"></span>`;
			retCode += value.substring(6, 11);
			retCode += `<span class="spacing"></span>`;
			retCode += value.substring(11, value.length);
			//console.log("Initial: ", value, " retCode: ", retCode);
			/*
			#_#####_#####_#
			6_28240_00500_7
			6 28240 00500 7
			*/
			return `${retCode}`;
		}
		else
		{
			return "";
		}
	}
	
	/**
	 * Gets the Kosher status of a product. Ensures the value is not undefined.
	 * @param  {string} value	The value from the database.
	 * @returns string			An HTML element wrapped in a string, that will be inserted into the product description as an icon.
	 */
	public getKosher(value: string): string {
		if (typeof(value) !== "undefined" && value !== null && value.toLowerCase() === "y")
		{
			return `<i title="Kosher" class="oci-kosher ml-2 mr-1" style="font-size: 1.2rem; font-weight: bold;"></i>`;
		}
		else
		{
			return "";
		}
	}
	
	/**
	 * Gets the stock item status of a product. Ensures the value is not undefined.
	 * @param  {string} value	The value from the database.
	 * @returns string			An HTML element wrapped in a string, that will be inserted into the product description as an icon.
	 */
	public getStock(value: string): string {
		if (typeof(value) !== "undefined" && value !== null && value.toLowerCase() === "y")
		{
			return `<i title="Stock Item" class="fas fa-check ml-2 mr-1" style="font-size: 1rem; font-weight: bold;"></i>`;
		}
		else
		{
			return "";
		}
	}
}

/**
 * Holds the methods marked as async to allow for threaded awaits
 */
export class AsyncFunctions {
	/**
	 * Makes an asynchronous call to update the brands list.
	 * @returns Promise
	 * @see jqwidgets.jqxListBox
	 */
	public async updateBrandsAsync(): Promise<jqwidgets.jqxListBox> {
		let $var = window.ocConVars, $func = window.ocControl, $event = window.ocEvents;
		if($var.chipSet.chipExists("brands", true)) {
			$var.chipSet.removeChip("brands", true);
		}
		let prom: Promise<jqwidgets.jqxListBox> = new Promise((resolve, reject) => {
			$func.loadBrandDataTypes();
			// Create the Filter by Brand listbox data adapter
			$var.brandAdapter = new $.jqx.dataAdapter($var.brandSource, {
				uniqueDataFields: ["BrandTitle"],
				autoBind: true,
				loadComplete: (_data:any) => {
					// Anonymous callback for loadComplete event. Responsible for updating the list box properly
					// Initialize local variables
					var tCheck: boolean = true, tDisable: boolean = false, tSource: { BrandTitle: string; }[], tGroup: string;

					if($var.brandAdapter.records.length === 0) {
						// If no records were returned, update the local variables to reflect as much
						tSource = [{
							BrandTitle: "None"
						}];
						tGroup = "";
						tCheck = false;
						tDisable = true;
					} else {
						// If records were returned, set the local source toi be the returned records,
						// the group to be by the brand title (organization name), checkmarks enabled, and
						// box disabled to be false.
						tSource = $var.brandAdapter.records;
						tGroup = "BrandTitle";
						tCheck = true;
						tDisable = false;
					}
					// Create the jqWidgets list box using the defined local variables
					$var.brandBox = jqwidgets.createInstance("#tBrandBox", 'jqxListBox', {
						source: tSource,
						displayMember: "BrandTitle",
						valueMember: "BrandTitle",
						checkboxes: tCheck,
						disabled: tDisable,
						autoHeight: true,
						width: "100%",
						theme: "orgcon"
					});
					if($var.brandBox !== null) {
						resolve($var.brandBox);
					} else {
						reject("failed to retrieve listbox");
					}
				}
			});
		});

		return prom;
	}

	/**
	 * Makes an asynchronous call to update the brand-categories list
	 * @returns Promise
	 * @see jqwidgets.listbox
	 */
	public async updateSubBrandsAsync(): Promise<jqwidgets.jqxListBox> {
		let $var = window.ocConVars, $func = window.ocControl, $event = window.ocEvents;
		if($var.chipSet.chipExists("subbrands", true)) {
			$var.chipSet.removeChip("subbrands", true);
		}

		let prom: Promise<jqwidgets.jqxListBox> = new Promise((resolve, reject) => {
			$func.loadSubDataTypes();

			$var.subBrandAdapter = new $.jqx.dataAdapter($var.subcatSource, {
				uniqueDataFields: ["BrandTitle", "BrandName"],
				autoBind: true,
				loadComplete: (data: any) => {
					let tCheck: boolean = true, tDisable: boolean = false, tSource: { BrandTitle: string; BrandName: string; Commodity: string; }[], tGroup: string;

					if($var.subBrandAdapter.records.length === 0)
					{
						tSource = [{
							BrandTitle: "None",
							BrandName: "None",
							Commodity: "None"
						}];
						tGroup = "";
						tCheck = false;
						tDisable = true;
					}
					else
					{
						tSource = $var.subBrandAdapter.records;
						tGroup = "BrandTitle";
						tCheck = true;
						tDisable = false;
					}
					$var.subBrandBox = jqwidgets.createInstance("#tSubBrandBox", "jqxListBox", {
						source: tSource,
						groupMember: tGroup,
						displayMember: "BrandName",
						valueMember: "BrandName",
						checkboxes: tCheck,
						disabled: tDisable,
						autoHeight: true
					});
					if($var.subBrandBox !== null)
					{
						resolve($var.subBrandBox)
					}
					else
					{
						reject("failed to retrieve listbox");
					}
				}
			});
		});

		return prom;
	}

	/**
	 * Makes an asynchronous call to update the herbs list.
	 * @returns Promise
	 * @see jqwidgets.listbox
	 */
	public async updateHerbsAsync(): Promise<jqwidgets.jqxListBox> {
		let $var = window.ocConVars, $func = window.ocControl, $event = window.ocEvents;
		// Check to see if the chip already exists above the grid
		if($var.chipSet.chipExists("commodity", true)) {
			// And remove it if it does.
			$var.chipSet.removeChip("commodity", true);
		}


		let prom:Promise<jqwidgets.jqxListBox> =  new Promise((resolve, reject) => {
			$func.loadHerbDataTypes();

			$var.commodityAdapter = new $.jqx.dataAdapter($var.commSource, {
				uniqueDataFields: ["Commodity"],
				autoBind: true,
				loadComplete: (_data: any) => {
					let uRecords = [], tSource: any[] | { BrandTitle: string; BrandName: string; Commodity: string; }[];
					$var.commodityAdapter.records.filter(function(item) {
						if(item.Commodity !== null )
						{
							uRecords.push(item);
						}
					});

					let tCheck: boolean = true, tDisable: boolean = false, tFilter: boolean = true;

					if(uRecords.length === 0)
					{
						tSource = [{
							BrandTitle: "None",
							BrandName: "None",
							Commodity: "None"
						}];
						tCheck = false;
						tDisable = true;
						tFilter = false;
					}
					else
					{
						tSource = uRecords.sort(function(a,b){
							let commA = a.Commodity.toLowerCase(), commB = b.Commodity.toLowerCase();
							if(commA < commB) return -1;
							if(commA > commB) return 1;
							return 0;
						});
						tCheck = true;
						tDisable = false;
						tFilter = true;
					}

					$var.herbBox = jqwidgets.createInstance("#tCommodityBox", "jqxListBox", {
						source: tSource,
						displayMember: "Commodity",
						valueMember: "Commodity",
						checkboxes: tCheck,
						disabled: tDisable,
						autoHeight: true,
						filterable: tFilter
					});

					if($var.herbBox !== null)
					{
						resolve($var.herbBox);
					}
					else
					{
						reject("unable to retrieve listbox");
					}
				}
			});
		});

		return prom;
	}
}

/**
 * Interface to hold strongly typed rowData definition
 */
interface rowData {
	BrandTitle: string|null;
	BrandName: string|null;
	BrandCode: string|null;
	SortAsBulk: string|null;
	Commodity: string|null;
	ItemNumber: string|null;
	Description: string|null;
	DescriptionFull: string|null;
	BotName: string|null;
	Size: string|null;
	UpcCode: string|null;
	Kosher: string|null;
	Designation: string|null;
	MarketingComments: string|null;
	WebCatalog: string|null;
	picThumbnail: string|null;
	picMain: string|null;
	CommodityNotes: string|null;
	Available: string|null;
	StockItem: string|null;
	bBrand: string|null;
	bComCode: string|null;
	bCommodity: string|null;
	uid: string|number|any;

	//Products: Array<rowData>|Array<bulkProducts>|null;
	Products: Array<rowData>|Array<bulkProducts>|null;
}

/**
 * Interface to hold strongly typed bulk product information.
 */
interface bulkProducts {
	"Organic Connections": Array<rowData>;
	"Frontier Co-Op" : Array<rowData>;
	"Miski Organics": Array<rowData>;
}
/**
 * Interface to hold a loosely defined array-like object
 */
interface LooseObject {
    [key: string]: any
}
/**
 * Interface to hold window bounds information.
 */
interface BoundingRect {
	top: boolean;
	left: boolean;
	bottom: boolean;
	right: boolean;
	any: boolean;
	all: boolean;
}

/**
 * Interface to hold brand-category data type.
 */
interface SubBrandType {
	BrandName:string;
	BrandTitle: string;
	Commodity: any;
	uid: number;
}